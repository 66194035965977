import { combineReducers } from 'redux'
import GeneralReducer from './GeneralReducer'
import UserReducer from './UserReducer'
import ShopReducer from './ShopReducer'
import CovidReducer from './CovidReducer'
import AnalyticsReducer from './AnalyticsReducer'
import BaseComponentsReducer from './BaseComponentsReducer'
import DiscountReducer from './DiscountReducer';

export default combineReducers({
  general: GeneralReducer,
  user: UserReducer,
  shop: ShopReducer,
  covid: CovidReducer,
  analytics: AnalyticsReducer,
  baseComponents: BaseComponentsReducer,
  discount: DiscountReducer,
});
