import {
    Alert,
    Button,
    Col, Form,
    FormFeedback,
    FormGroup, FormText,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter, ModalHeader,
    Row,
    Table
} from "reactstrap";
import React, {forwardRef, useEffect, useRef, useState} from "react";
import * as _ from "lodash";

const GuestFormGroup = forwardRef(function({labelText, placeholder, defaultValue, errorText, regex, toggleError, isNic}, ref) {
    const [value, setValue] = useState('')
    const [error, setError] = useState(false)

    const id = _.uniqueId('guestFormGroup')
    const onChangeValue = (text) => {
        if (isNic) {
            const isError = !nicValidation(text)

            ref.current = {value: text, error: isError}
            setValue(text)
            setError(isError)
        } else {
            if (regex instanceof RegExp) {
                ref.current = {value: text, error: !regex.test(text)}
                setError(!regex.test(text))
                setValue(text)
            } else {
                ref.current = {value: text, error: false}
                setValue(text)
                setError(false)
            }
        }
    }

    const nicValidation = (nicNumber) =>{
        let result = false;

        if (nicNumber.length === 10 && !isNaN(nicNumber.substr(0, 9)) && isNaN(nicNumber.substr(9, 1).toLowerCase()) && ['x', 'v'].includes(nicNumber.substr(9, 1).toLowerCase())) {
            result = true;
        } else if (nicNumber.length === 12 && !isNaN(nicNumber)) {
            result = true;
        } else {
            result = false;
        }
        return result;
    }

    useEffect(() => {
        if (typeof defaultValue === "string") {
            onChangeValue(defaultValue)
        } else {
            ref.current = {value: '', error: true}
        }
    }, []);

    useEffect(() => {
        if (typeof toggleError === 'boolean') {
            onChangeValue(ref.current.value)
        }
    }, [toggleError]);

    return(<FormGroup>
        <Label for={id}>{labelText}</Label>
        <Input
            id={id}
            value={value}
            invalid={error}
            name="text"
            placeholder={placeholder ? placeholder : ''}
            type="text"
            onChange={(e) => {
                onChangeValue(e.target.value)
            }}
        />
        <FormFeedback valid={error}>{errorText ? errorText : ''}</FormFeedback>
    </FormGroup>)
})

function FacilityGuest({guestArray, minimumGuestCount, onUpdateGuestList}) {
    const [openGuestsModal, setOpenGuestsModal] = useState(false)
    const [guestList, setGuestList] = useState([])
    const [toggleError, setToggleError] = useState()
    const [isNicAlreadyExit, setIsNicAlreadyExit] = useState(false)

    const nameRef = useRef()
    const nicRef = useRef()

    const onAddGuest = () => {
        if (!nameRef.current.error && !nicRef.current.error) {
            if (guestList.some(guest => guest.nic === nicRef.current.value)) {
                setIsNicAlreadyExit(true)
            } else {
                let guest = {
                    fullName: nameRef.current.value,
                    nic: nicRef.current.value,
                }
                setIsNicAlreadyExit(false)
                setGuestList([...guestList, guest])
            }
        } else {
            setIsNicAlreadyExit(false)
            setToggleError(!toggleError)
        }
    }

    const onRemoveGuest = (index) => {
        const newList = [...guestList];
        newList.splice(index, 1);

        setIsNicAlreadyExit(_.get(newList, `[${index}].nic`, null) === _.get(nicRef, 'current.value', '').trim())
        setGuestList(newList)
    }

    const closeModal = () => {
        setIsNicAlreadyExit(false)
        setToggleError(undefined)
        setGuestList([])
        setOpenGuestsModal(!openGuestsModal)
    }

    useEffect(() => {
        if (openGuestsModal && _.isArray(guestArray)) {
            setGuestList(guestArray)
        }
    }, [openGuestsModal]);

    return (<>
        <Modal isOpen={openGuestsModal} size="lg" backdrop='static'>
            <ModalHeader  toggle={closeModal}>Manage Guests</ModalHeader>
            <ModalBody>
                {openGuestsModal && <Form>
                    <Row>
                        <Col md={6}>
                            <GuestFormGroup ref={nameRef} toggleError={toggleError} placeholder='Enter guest full name' labelText='Full Name' regex={/^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$/} />
                        </Col>
                        <Col md={6}>
                            <GuestFormGroup ref={nicRef} toggleError={toggleError} placeholder='Enter guest NIC' labelText='NIC' isNic={true} regex={/^[a-zA-Z0-9 ]{3,40}$/} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {isNicAlreadyExit && <FormGroup>
                                <FormText color='danger'>{nicRef.current.value} is already exit</FormText>
                            </FormGroup>}
                            <FormGroup>
                                <Button
                                    id='booking-ticket-guest-add-btn'
                                    size="md"
                                    className='my-auto ml-auto an-btn-info an-emp-add-btn shadow-sm btn btn-secondary'
                                    onClick={onAddGuest}
                                >Add</Button>
                            </FormGroup>
                        </Col>
                    </Row>
                    {guestList.length < minimumGuestCount && <Row>
                        <Col>
                            <Alert color="warning">A minimum of {minimumGuestCount} guests is required.</Alert>
                        </Col>
                    </Row>}
                </Form>}
                <Table className="mt-4">
                    <thead>
                    <tr>
                        <th>Full Name</th>
                        <th>NIC</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {guestList.map((guest, index) => {
                        return (
                            <tr key={guest.nic}>
                                <td>{guest.fullName}</td>
                                <td>{guest.nic}</td>
                                <td><Button id='booking-ticket-guest-remove-btn' className="an-btn-info" onClick={() => { onRemoveGuest(index) }}>Remove</Button></td>
                            </tr>
                        )
                    })}
                    </tbody>
                </Table>
            </ModalBody>
            <ModalFooter>
                {_.isFunction(onUpdateGuestList) &&
                    <Button color="secondary"
                            id={`booking-ticket-guest-${_.size(guestArray) === 0 ? 'submit' : _.size(guestList) >= minimumGuestCount ? 'update' : 'remove-all'}-btn`}
                            className='an-btn-info an-emp-add-btn shadow-sm btn btn-secondary'
                            disabled={_.size(guestArray) === 0 && _.size(guestList) < minimumGuestCount}
                            onClick={() => {
                                if (_.isFunction(onUpdateGuestList)) {
                                    onUpdateGuestList(_.size(guestList) >= minimumGuestCount ? guestList : [])
                                    setIsNicAlreadyExit(false)
                                    setToggleError(undefined)
                                    setGuestList([])
                                    setOpenGuestsModal(!openGuestsModal)
                                }
                            }}
                    >{_.size(guestArray) === 0 ? 'Submit' : _.size(guestList) >= minimumGuestCount ? 'Update' : 'Remove All Guest'}</Button>}
            </ModalFooter>
        </Modal>
        <Button id={`booking-ticket-add-guest-btn`} onClick={() => setOpenGuestsModal(true)}>Add Guests</Button>
    </>)
}

export default FacilityGuest
