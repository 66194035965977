import React, {forwardRef, memo, useEffect, useRef, useState} from "react";
import {checkPhoneNumber} from "../../utils/common";
import {Col, FormFeedback, FormGroup, FormText, Input, Label, Row} from "reactstrap";
import {configs} from "../../utils/configs";
import {connect} from "react-redux";

const UserDetailsRef = memo(forwardRef(function (props, ref) {
    const {userFirstName, userLastName, userPhoneNumber, userEmail, isDisabledInputs, toggleUserDetailsValidate} = props;

    const [firstName, setFirstName] = useState()
    const [firstNameError, setFirstNameError] = useState(false)

    const [lastName, setLastName] = useState('')
    const [lastNameError, setLastNameError] = useState(false)

    const [email, setEmail] = useState(undefined)
    const [emailError, setEmailError] = useState(false)

    const [phoneNumber, setPhoneNumber] = useState(undefined)
    const [phoneNumberError, setPhoneNumberError] = useState(false)
    const isInputValuesChange = useRef(false)

    const isValidUsername = (username) => {
        const usernameRegex = /^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$/;

        return usernameRegex.test(username) && username.length >= 3 && username.length <= 20;
    }

    const isValidEmail = (email) => {
        const emailRegex = new RegExp("^[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?$");

        return emailRegex.test(email);
    }

    const onValidate = () => {
        let isValid = true

        if (firstName !== undefined || typeof toggleUserDetailsValidate === 'boolean') {
            if (!isValidUsername(firstName ? firstName : '')) {
                isValid = false
            }

            setFirstNameError(!isValidUsername(firstName ? firstName : ''))
            ref.current.userFirstName = firstName
        } else if (isInputValuesChange.current) {
            setFirstNameError(true)
            ref.current.userFirstName = ''
        }

        if (phoneNumber !== undefined || typeof toggleUserDetailsValidate === 'boolean') {
            if (!checkPhoneNumber(phoneNumber ? phoneNumber : '')) {
                isValid = false
            }

            setPhoneNumberError(!checkPhoneNumber(phoneNumber ? phoneNumber : ''))
            ref.current.userPhoneNumber = phoneNumber
        } else if (isInputValuesChange.current) {
            setPhoneNumberError(true)
            ref.current.userPhoneNumber = ''
        }

        if (lastName === '' || lastName === undefined) {
            setLastNameError(false)
            ref.current.userLastName = ''
        } else if (lastName !== '' && lastName !== undefined) {
            if (!isValidUsername(lastName)) {
                isValid = false
            }

            setLastNameError(!isValidUsername(lastName))
            ref.current.userLastName = lastName
        }

        if (email === '' || email === undefined) {
            setEmailError(false)
            ref.current.userEmail = ''
        } else if (email !== '' && email !== undefined) {
            if (!isValidEmail(email)) {
                isValid = false
            }

            setEmailError(!isValidEmail(email))
            ref.current.userEmail = email
        }

        ref.current.isValidUserDetails = isValid
    }

    useEffect(() => {
        setFirstName(userFirstName)
        setLastName(userLastName)
        setPhoneNumber(userPhoneNumber)
        setEmail(userEmail)
    }, [userFirstName, userLastName, userPhoneNumber, userEmail]);

    useEffect(() => {
        ref.current.isValidUserDetails = false
    }, []);

    useEffect(() => {
        onValidate()
    }, [firstName, lastName, email, phoneNumber]);

    useEffect(() => {
        if (isInputValuesChange.current) {
            ref.current.isValidUserDetails = !firstNameError && !lastNameError && !phoneNumberError && !emailError
        } else {
            ref.current.isValidUserDetails = false
        }
    }, [firstNameError, lastNameError, phoneNumberError, emailError]);

    useEffect(() => {
        if (typeof toggleUserDetailsValidate === 'boolean') {
            onValidate()
        } else {
            ref.current.isValidUserDetails = false
        }
    }, [toggleUserDetailsValidate]);


    return (
        <>
            <Row form>
                <Col md={6}>
                    <FormGroup>
                        <Label ><span className="required">*</span>First Name</Label>
                        <Input
                            maxLength={20}
                            id="booking-ticket-user-firstName"
                            value={firstName || ''}
                            invalid={firstNameError}
                            placeholder="Enter customer first name"
                            disabled={isDisabledInputs}
                            onChange={(e) => {
                                isInputValuesChange.current = true
                                setFirstName(e.target.value)
                            }} />
                        <FormFeedback valid={!firstNameError}>Please insert a valid name</FormFeedback>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label >Last Name</Label>
                        <Input
                            id="booking-ticket-user-last-name"
                            value={lastName || ''}
                            placeholder="Enter customer last name"
                            invalid={lastNameError}
                            disabled={isDisabledInputs}
                            onChange={(e) => {
                                isInputValuesChange.current = true
                                setLastName(e.target.value)
                            }}
                        />
                        <FormFeedback valid={!lastNameError}>Please insert a valid name</FormFeedback>
                    </FormGroup>
                </Col>
            </Row>
            <Row form>
                <Col md={6}>
                    <FormGroup>
                        <Label ><span className="required">*</span>Phone</Label>
                        <Input
                            id="booking-ticket-user-phone"
                            type="tel"
                            invalid={phoneNumberError}
                            value={phoneNumber || ''}
                            placeholder="Enter customer phone"
                            disabled={isDisabledInputs}
                            onChange={(e) => {
                                isInputValuesChange.current = true
                                setPhoneNumber(e.target.value)
                            }} />
                        <FormFeedback valid={!phoneNumberError}>Invalid phone number</FormFeedback>
                        <FormText>{configs.phoneNumberExample}</FormText>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label >Email Address</Label>
                        <Input
                            id="booking-ticket-user-email"
                            type="email"
                            invalid={emailError}
                            value={email || ''}
                            placeholder="Enter Email"
                            disabled={isDisabledInputs}
                            onChange={(e) => {
                                isInputValuesChange.current = true
                                setEmail(e.target.value)
                            }}
                        />
                        <FormFeedback valid={!emailError}>Not a valid email address</FormFeedback>
                    </FormGroup>
                </Col>
            </Row>
        </>
    )
}))

const mapStateToProps = (state) => {
    return {
        bookingTicketReduxConfig: state.baseComponents.bookingTicketReduxConfig,
    };
};

const UserDetails = connect(mapStateToProps, {}, null, {forwardRef: true})(UserDetailsRef);

export default UserDetails
