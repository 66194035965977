import {
    CONFIG_ADD_APPOINTMENT_MODAL,
    TOGGLE_ADD_APPOINTMENT_MODAL,
    TOGGLE_BOOKING_TICKET_MODAL,
    UPDATE_CONFIG_BOOKING_TICKET_MODAL
} from '../actions/types'

export const toggleAddAppointmentModal = () => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_ADD_APPOINTMENT_MODAL })
    }
}

export const configAddAppointmentModal = (configuration) => {
    return async (dispatch) => {
        dispatch({ type: CONFIG_ADD_APPOINTMENT_MODAL, payload: { configuration } })
    }
}

export const toggleBookingTicketModal = (ticketConfiguration) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_BOOKING_TICKET_MODAL, payload: { ticketConfiguration } })
    }
}

export const updateConfigBookingTicketModal = (configuration = {}) => {
    return async (dispatch) => {
        dispatch({ type: UPDATE_CONFIG_BOOKING_TICKET_MODAL, payload: { configuration } })
    }
}
