import {
  GET_DISCOUNT_PLANS,
  GET_DISCOUNT_PLANS_SUCCESS,
  GET_DISCOUNT_PLANS_ERROR,
  SELECT_DISCOUNT_PLAN,
  SHOW_UPDATE_DISCOUNT_MODAL,
  GET_DISCOUNT_PLAN,
  GET_DISCOUNT_PLAN_SUCCESS,
  GET_DISCOUNT_PLAN_ERROR,
} from "../actions/types";

const INITIAL_STATE = {
  discountPlans: [],
  selectedDiscountPlan: {},
  showUpdateDiscountModal: false,
  totalDiscountPlans: 0,
  isFetchingDiscountPlans: false,
  isFetchingDiscountPlan: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_DISCOUNT_PLANS:
      return {
        ...state,
        isFetchingDiscountPlans: true,
      };
    case GET_DISCOUNT_PLANS_SUCCESS:
      return {
        ...state,
        discountPlans: action.payload.data.content,
        totalDiscountPlans: action.payload.data.totalPages,
        isFetchingDiscountPlans: false,
      };
    case GET_DISCOUNT_PLANS_ERROR:
      return {
        ...state,
        isFetchingDiscountPlans: false,
        showUpdateDiscountModal: false,
      };
    case SELECT_DISCOUNT_PLAN:
      return {
        ...state,
        selectedDiscountPlan: action.payload,
      };
    case SHOW_UPDATE_DISCOUNT_MODAL:
      return {
        ...state,
        showUpdateDiscountModal: action.payload,
      };
    case GET_DISCOUNT_PLAN:
      return {
        ...state,
        isFetchingDiscountPlan: true,
      };
    case GET_DISCOUNT_PLAN_SUCCESS:
      return {
        ...state,
        selectedDiscountPlan: action.payload.data.data,
        isFetchingDiscountPlan: false,
      };
    case GET_DISCOUNT_PLAN_ERROR:
      return {
        ...state,
        selectedDiscountPlan: null,
        isFetchingDiscountPlan: false,
      };

    default:
      return state;
  }
};
