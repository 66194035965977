import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormFeedback,
    FormGroup,
    Label,
    UncontrolledDropdown
} from "reactstrap";
import * as _ from "lodash";
import {configs} from "../../utils/configs";
import React, {useEffect, useState, memo, forwardRef} from "react";

const BookingDropdown = memo(forwardRef(function (props, ref) {
    const {id, disabled, itemList, label, required, displayLabel, displayLabel2, displayLabel3, displayLabel4, onSelectItem, toggleValidate, validateText, defaultSelectedItem} = props
    const [selectedListItem, setSelectedListItem] = useState()

    useEffect(() => {
        if (ref && ref?.current?.selectedItem) {
            setSelectedListItem(ref.current.selectedItem)
            ref.current.isValid = true
        }
    }, []);

    useEffect(() => {
        if (ref && typeof ref?.current?.isValid === 'boolean') {
            setSelectedListItem(ref.current.isValid && ref.current.selectedItem ? ref.current.selectedItem: undefined)
        }
    }, [toggleValidate]);

    useEffect(() => {
        if (defaultSelectedItem) {
            onSelectListItem(defaultSelectedItem)
        }
    }, [defaultSelectedItem]);

    const onSelectListItem = (item) => {
        if (ref?.current) {
            ref.current.selectedItem = item
            ref.current.isValid = true
        }

        setSelectedListItem(item)

        if (_.isFunction(onSelectItem)) {
            onSelectItem(item)
        }
    }

    return (
        <FormGroup>
            {_.isArray(itemList) && _.size(itemList) > 0 && <>
                <Label >{required && <span className="required">*</span>}{label}</Label>
                <UncontrolledDropdown disabled={disabled}>
                    <DropdownToggle
                        id={id}
                        caret
                        color={`${(ref && typeof ref.current.isValid === 'boolean' && !ref.current.isValid) ? 'danger' : 'info'}`}>
                        {_.isEmpty(selectedListItem) ? 'Select a option' : selectedListItem[displayLabel]}
                    </DropdownToggle>
                    <DropdownMenu className="w-100"
                                  modifiers={{
                                      setMaxHeight: {
                                          enabled: true,
                                          order: 890,
                                          fn: (data) => {
                                              return {
                                                  ...data,
                                                  styles: {
                                                      ...data.styles,
                                                      overflow: 'auto',
                                                      maxHeight: '280px',
                                                      zIndex: 2000,
                                                  },
                                              };
                                          },
                                      },
                                  }}>
                        {_.map(itemList, (obj, index) => {
                            return (
                                <DropdownItem
                                    id={`${id}-dropdown-item-${index}`}
                                    disabled={obj.status !== "Active"}
                                    key={index}
                                    onClick={() => onSelectListItem(obj)}
                                    className='px-2'
                                >
                                    <div className="d-flex flex-column">
                                        <div className='d-flex flex-row'>
                                            <div className='text-truncate'>{`${obj[displayLabel]} ${displayLabel4 && obj.status !== "Active" ? displayLabel4 : ''}`}</div>
                                            {displayLabel3 && <div className="ml-auto" >{configs.currencyPrefix}{_.get(obj, displayLabel3, "")}</div>}
                                        </div>
                                        {displayLabel2 && <p className="text-muted text-truncate">{_.get(obj, displayLabel2, "")}</p>}
                                    </div>
                                </DropdownItem>
                            )
                        })}
                    </DropdownMenu>
                </UncontrolledDropdown>
                {validateText && (ref && typeof ref.current.isValid === 'boolean' && !ref.current.isValid) &&
                    <FormFeedback className='d-block' valid={false}>{validateText}</FormFeedback>}
                </>
            }
        </FormGroup>
    )
}))

export default BookingDropdown
