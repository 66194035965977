import React, {memo, useEffect, useState} from "react";
import {Button, ButtonGroup, Col, Row} from "reactstrap";
import * as _ from "lodash";
import {APPOINTMENT_TYPE} from "../../utils/common";

const BookingTypeButtonGroup = memo(function ({disabled, bookingType, toggleBookingType}) {
    const [selectedType, setSelectedType] = useState(APPOINTMENT_TYPE.MULTIPLE)

    useEffect(() => {
        if (bookingType) {
            setSelectedType(bookingType)
        }
    }, []);

    const onChangeBookingType = (type) => {
        setSelectedType(type)

        if (_.isFunction(toggleBookingType)) {
            toggleBookingType(type)
        }
    }

    return(
        <Row>
            <Col xs={12} className='pb-3'>
                <ButtonGroup className='w-100'>
                    <Button disabled={disabled}
                            id="booking_ticket_type_multiple"
                            value={APPOINTMENT_TYPE.MULTIPLE}
                            active={selectedType === APPOINTMENT_TYPE.MULTIPLE}
                            outline
                            className={selectedType === APPOINTMENT_TYPE.MULTIPLE ? 'booking_ticket_primary_btn' : 'booking_ticket_inactive_btn'}
                            onClick={(e) =>onChangeBookingType(e.target.value)}>
                        Multiple
                    </Button>
                    <Button
                            id="booking_ticket_type_recurring"
                            disabled={disabled}
                            value={APPOINTMENT_TYPE.RECURRING}
                            outline
                            className={selectedType === APPOINTMENT_TYPE.RECURRING ? 'booking_ticket_primary_btn' : 'booking_ticket_inactive_btn'}
                            active={selectedType === APPOINTMENT_TYPE.RECURRING}
                            onClick={(e) =>onChangeBookingType(e.target.value)}>
                        Recurring
                    </Button>
                </ButtonGroup>
            </Col>
        </Row>
    )
})

export default BookingTypeButtonGroup
