import _ from "lodash";
import Images from "../../assets/images";
import {Badge, Table} from "reactstrap";
import {configs} from "../../utils/configs";
import React from "react";

function BookingTicketCart({ appointments, removeAppointmentFromBulk, facilities, services }) {
    return (
        <Table bordered hover responsive className="mt-2" style={{ fontSize: "0.8rem" }}>
            <thead>
            <tr>
                <th width="4%"></th>
                <th width={`${facilities ? '44' : '54'}%`}>Date</th>
                <th width="19%">Service</th>
                {facilities && <th width="14%">Facility</th>}
                <th width="8%">Start</th>
                <th width="8%">End</th>
                <th width="4%">Amount</th>
                <th width="4%">Discount</th>
                <th width="4%">Net</th>
            </tr>
            </thead>
            <tbody>
            {appointments.map((appointment, i) => {
                let serviceName = _.get(_.find(services, { 'serviceId': appointment.serviceId}), 'serviceName', '');

                serviceName = serviceName ? serviceName : appointment.serviceName;
                return (
                    <tr key={_.uniqueId('booking_ticket_cart_item')}>
                        <td style={{textAlign: "center"}}><img id={`booking-ticket-cart-item-remove-${i}`} src={Images.deleteIcon} width="15" height="15" style={{cursor: "pointer"}} onClick={() => removeAppointmentFromBulk(i)} /></td>
                        <td>{appointment.appointmentDate}</td>
                        <td>{serviceName} <Badge className="label mr-2" color="info" >{appointment.halfCourtName}</Badge></td>
                        {facilities && <td>{_.get(_.find(facilities, { 'facilityId': appointment.facilityId}), 'facilityName', '--')}</td>}
                        <td>{appointment.startTime}</td>
                        <td>{appointment.endTime}</td>
                        <td className="text-right">{configs.currencyPrefix}{Number.parseFloat(`${appointment.amount}`).toFixed(2)}</td>
                        <td className="text-right">{appointment.discountValue ? (appointment.discountMode === "PCT" ? appointment.discountValue*100 + "%" : appointment.discountValue) : null}</td>
                        <td className="text-right">{configs.currencyPrefix}{Number.parseFloat(`${appointment.netAmount}`).toFixed(2)}</td>
                    </tr>
                )
            })}
            <tr>
                <td colSpan={5}>Total Amount</td>
                <td colSpan={4} className="text-right">{configs.currencyPrefix}{Number.parseFloat(`${_.sumBy(appointments, a => Number(a.netAmount))}`).toFixed(2)}</td>
            </tr>
            </tbody>
        </Table>
    )
}

export default BookingTicketCart
