import React, {memo, useEffect, useMemo, useRef, useState} from "react";
import {connect} from "react-redux";
import moment from "moment";
import * as _ from "lodash";
import {Alert, Col, FormGroup, FormText, Input, Label, Row} from "reactstrap";
import BookingDropdown from "../BookingDropdown";
import {configs} from "../../../utils/configs";
import {getDiscountAmountByServiceId, getDiscountsForService} from "../../../api/discountsApi";
import {updateConfigBookingTicketModal} from "../../../actions/BaseComponentsActions";

const TIME_SPLIT_VALUE = 15

const enumBookingArea = {
    FULL: 'FULL',
    HALF: 'SHARE'
}

const FRACTION_DIGITS = 2

const SportTimer = memo(function (props) {
    const {bookingDate, selectedService, selectedFacility, availableBookings, cartListBookings, shopTimeArr, holidays, timeOffs, isLoadingBookingTicket, branchId, shopId, onClickAddBooking, isPickEditBooking, editBookingObject} = props

    const [startTimes, setStartTimes] = useState([])
    const [toggleStartTimes, setToggleStartTimes] = useState()
    const startTimeRef = useRef({selectedItem: undefined, isValid: true})

    const endTimeRef = useRef({selectedItem: undefined, isValid: true})
    const [endTimes, setEndTimes] = useState([])
    const [toggleEndTimes, setToggleEndTimes] = useState()

    const bookingAreaRef = useRef({selectedItem: undefined, isValid: true})
    const [bookingAreas, setBookingAreas] = useState([])
    const [toggleBookingAreas, setToggleBookingAreas] = useState()

    const [isCheckedFullDay, setIsCheckedFullDay] = useState()

    const [bookingObject, setBookingObject] = useState()

    const [discount, setDiscount] = useState(null)

    const fullDayRef = useRef()

    const buildTimeSlot = (startTime, endTime) => {
        const timeObject = {time: startTime, status: 'InActive', formattedTime: moment(`01/01/2024 ${startTime}`).format('HH:mm')}

        let isClasWithTimeOffs = false, isClasWithHolidays = false, isClashWithBooking = false

        _.forEach(timeOffs, (obj) => {
            let dateA = moment(obj.facilityOffDate).format('YYYY-MM-DD');
            let dateB = moment(bookingDate).format('YYYY-MM-DD');

            if (selectedFacility.facilityId === obj.facilityId && dateA === dateB) {
                if (obj.type === 'Full' && obj.status === 'Active') {
                    isClasWithTimeOffs = true
                    return false;
                } else if (obj.type === 'Half' && obj.status === 'Active') {
                    if (isTimeSlotClash(startTime, endTime, obj.startTime , obj.endTime)) {
                        isClasWithTimeOffs = true
                        return false
                    }
                }
            }
        });

        if (isClasWithTimeOffs) {
            return timeObject
        }

        _.forEach(holidays, (obj) => {
            let dateA = moment(obj.holiday).format('YYYY-MM-DD');
            let dateB = moment(bookingDate).format('YYYY-MM-DD');

            if (dateA === dateB) {
                if (obj.type === 'Full') {
                    isClasWithHolidays = true
                    return false;
                } else if (obj.type === 'Half') {
                    if (isTimeSlotClash(startTime, endTime, obj.startTime , obj.endTime)) {
                        isClasWithHolidays = true
                        return false
                    }
                }
            }
        });

        if (isClasWithHolidays) {
            return timeObject
        }

        if (_.size(getCourts(startTime, endTime)) > 0) {
            timeObject.status = 'Active'
        }

        return timeObject
    }

    const createEndTimeArray = (startTime) => {
        const serviceTime = selectedService.serviceHour * 60 + selectedService.serviceMinute
        const firstTime =  moment(`01/01/2024 ${startTime}`).add(serviceTime, 'minutes')
        const endTimesArray = [{time: firstTime.format('h:mm a'), status: 'Active', index: 1, formattedTime: firstTime.format('HH:mm')}]
        let canDo = true
        let index = 2

        while (canDo) {
            const shopDay = _.find(shopTimeArr, {day: moment(bookingDate).format('dddd')}, null)
            const closeTime = moment(shopDay.closeTime, 'HH:mm')
            const nextTimeStart = moment(_.first(endTimesArray).time, 'h:mm a')
            let nextTimeEnd =  moment(_.last(endTimesArray).time, 'h:mm a').add(serviceTime, 'minutes')

            if (!nextTimeEnd.isSameOrBefore(closeTime)) {
                canDo = false
            } else {
                const nextTimeObj = buildTimeSlot(nextTimeStart.format('HH:mm'), nextTimeEnd.format('HH:mm'))

                if (nextTimeObj.status === 'Active') {
                    endTimesArray.push({...nextTimeObj, time: nextTimeEnd.format('h:mm a'), index: index, formattedTime: nextTimeEnd.format('HH:mm')})
                    index++
                } else {
                    canDo = false
                }
            }
        }

        return endTimesArray
    }

    const onsSelectStartTime = () => {
        const startTime = startTimeRef.current.selectedItem.time
        const endTimesList = createEndTimeArray(startTime)

        endTimeRef.current.selectedItem = endTimesList[0]
        const endTime = endTimeRef.current.selectedItem.time

        const courts = getCourts(startTime, endTime)

        bookingAreaRef.current.selectedItem = courts[0]
        setBookingAreas(courts)
        setToggleBookingAreas(!toggleBookingAreas)
        setToggleEndTimes(!toggleEndTimes)
        setEndTimes(endTimesList)
    }

    const onSelectEndTIme = () => {
        const startTime = startTimeRef.current.selectedItem.time
        const endTime = endTimeRef.current.selectedItem.time
        const courts = getCourts(startTime, endTime)

        bookingAreaRef.current.selectedItem = courts[0]
        setBookingAreas(courts)
        setToggleBookingAreas(!toggleBookingAreas)
    }

    const getCourts = (startTime, endTime) => {
        const filteredCartListBookings = _.filter(cartListBookings, obj => moment(new Date(obj.appointmentDate)).isSame(moment(new Date(bookingDate))) && obj.facilityId === selectedFacility.facilityId)
        const currentBookings = _.filter([...filteredCartListBookings, ...availableBookings], booking => isTimeSlotClash(startTime, endTime, booking.startTime , booking.endTime))
        const areas = []

        if (selectedFacility.partialSupport && selectedService.isPartialSupport) {
            const partitionCount = selectedFacility.portionCount

            if (_.size(currentBookings) === 0) {
                areas.push(creatBookingAreaObject(enumBookingArea.FULL, null, _.uniqueId()))
                for (let i = 0; i < partitionCount; i++) {
                    areas.push(creatBookingAreaObject(enumBookingArea.HALF, i + 1, _.uniqueId()))
                }
            } else {
                if (!(_.find(currentBookings, {courtName: enumBookingArea.FULL})) && _.size(currentBookings) === _.size(_.filter(currentBookings, {facilityPortionCount: selectedFacility.portionCount}))) {
                    const groupedBookings = _.groupBy(currentBookings, 'startTime')
                    const courtNames = []
                    let sortingBookings = []
                    let availableCourtCount = 0

                    _.forEach(groupedBookings, (value, key) => {
                        groupedBookings[key] = value.sort((a, b) => moment(`01/01/2024 ${a.endTime}`).isBefore(moment(`01/01/2024 ${b.endTime}`)) ? -1 : 1)
                    })
                    _.forEach(Object.keys(groupedBookings).sort((a, b) => moment(`01/01/2024 ${a}`).isBefore(moment(`01/01/2024 ${b}`)) ? -1 : 1), (key) => {
                        sortingBookings = [...sortingBookings, ...groupedBookings[key]]
                    })
                    _.forEach(sortingBookings, (obj, index) => {
                        if (sortingBookings[index + 1]) {
                            if (!(moment(`01/01/2024 ${sortingBookings[index + 1].startTime}`).isSameOrAfter(moment(`01/01/2024 ${obj.endTime}`)))) {
                                courtNames.push(obj.courtName)
                                availableCourtCount++
                            }
                        } else {
                            courtNames.push(obj.courtName)
                            availableCourtCount++
                            return false
                        }
                    })

                    if ((partitionCount - availableCourtCount) >= 1) {
                        for (let i = 0; i < (partitionCount - availableCourtCount); i++) {
                            let number = i + 1

                            if (courtNames.indexOf(`${enumBookingArea.HALF}_${number}`) === -1) {
                                areas.push(creatBookingAreaObject(enumBookingArea.HALF, i + 1, _.uniqueId()))
                            } else {
                                let isTrue = true

                                while (isTrue) {
                                    if (courtNames.indexOf(`${enumBookingArea.HALF}_${number + 1}`) === -1) {
                                        isTrue = false
                                        courtNames.push(`${enumBookingArea.HALF}_${number + 1}`)
                                        areas.push(creatBookingAreaObject(enumBookingArea.HALF, number + 1, _.uniqueId()))
                                    }

                                    number++
                                }
                            }
                        }
                    }
                }
            }
        } else if (_.size(currentBookings) === 0) {
            areas.push(creatBookingAreaObject(enumBookingArea.FULL))
        }

        return areas
    }

    const onCreateBooking = async (halfCourtName) => {
        if (halfCourtName) {
            let otherPriceAmount = 0
            const booking = {
                uniqueId: _.uniqueId('booking_item_'),
                branchId,
                shopId: shopId,
                isWalking: "true",
                guestCustomer: false,
                // email: this.state.email,
                employeeId: null,
                facilityId: selectedFacility.facilityId,
                serviceId: selectedService.serviceId,
                // firstName: this.state.firstName,
                // lastName: this.state.lastName,
                // mobileNo: this.state.mobileNo,
                appointmentDate: moment(bookingDate).format('YYYY-MM-DD'),
                specialNote: '',
                halfCourtName: (fullDayRef.current && fullDayRef.current.checked) ? enumBookingArea.FULL : halfCourtName,
                courtName: (fullDayRef.current && fullDayRef.current.checked) ? enumBookingArea.FULL : halfCourtName,
                isPartial: (fullDayRef.current && fullDayRef.current.checked) ? false : halfCourtName !== enumBookingArea.FULL,
                isFullDay: fullDayRef.current ? fullDayRef.current.checked : false,
                startTime: startTimeRef.current.selectedItem.formattedTime,
                endTime: endTimeRef.current.selectedItem.formattedTime,
                customizedSlots: endTimeRef.current.selectedItem.index,
                amount: 0,
                additionalPricingListIds: [],
                guests: [],
                facilityPortionCount: selectedFacility.portionCount,
                bookedPortionCount: 1,
                facilityPortionPrice: Number(`${_.get(_.find(selectedFacility.portionPriceDTORsList, {serviceId: selectedService.serviceId, status: "Active"}), 'portionPrice', 0)}`) > 0 ? Number(`${_.get(_.find(selectedFacility.portionPriceDTORsList, {serviceId: selectedService.serviceId, status: "Active"}), 'portionPrice', 0)}`) : selectedService.servicePartialPrice ? selectedService.servicePartialPrice : selectedService.serviceFullPrice,
                discountMode: null,
                discountValue: null,
                netAmount: null
            }

            if (fullDayRef.current && fullDayRef.current.checked) {
                const shopDay = _.find(shopTimeArr, {day: moment(bookingDate).format('dddd')}, null)

                if (shopDay) {
                    booking.startTime = shopDay.openTime
                    booking.endTime = shopDay.closeTime
                }

                const duration = moment(`01/01/2024 ${booking.endTime}`).diff(moment(`01/01/2024 ${booking.startTime}`), 'minutes');
                const serviceTime = (selectedService.serviceHour * 60) + selectedService.serviceMinute;

                booking.amount = selectedService.fullDayPrice > 0 ? Number(`${selectedService.fullDayPrice}`) : Number(`${((Number(selectedService.serviceFullPrice) / serviceTime) * Number(duration))}`)
            } else {
                booking.amount = booking.isPartial && selectedService.servicePartialPrice > 0 ? booking.facilityPortionPrice * booking.customizedSlots : booking.customizedSlots * selectedService.serviceFullPrice
            }

            let otherPricingList = getOtherPricing(_.get(selectedService, 'isAdditionalPricingSupport', false), _.get(selectedService, 'additionalPricingList', []), booking.appointmentDate, booking.startTime, booking.endTime, booking.isFullDay)

            if (_.size(otherPricingList) > 0) {
                const updatedPriceList = _.map(otherPricingList, (obj) => {
                    // const charging = Number(booking.isFullDay ? obj.meta.fullPrice : (obj.otherTimeMinutes * (((booking.isPartial && obj.meta.partialPrice) ? obj.meta.partialPrice : obj.meta.fullPrice) / obj.serviceChargeMinutes)))
                    const charging = Number(booking.isFullDay ? obj.meta.fullPrice : (obj.otherTimeMinutes * ((booking.isPartial ? (_.isNaN(obj.meta.partialPrice) ? 0 : obj.meta.partialPrice) : (_.isNaN(obj.meta.fullPrice) ? 0 : obj.meta.fullPrice)) / obj.serviceChargeMinutes)))
                    otherPriceAmount = otherPriceAmount + charging

                    return {...obj, charging}
                });

                booking.amount = booking.amount + otherPriceAmount;
                booking.additionalPricingListIds = _.map(otherPricingList, obj => obj.meta.id)
                otherPricingList = updatedPriceList
            }

            booking.amount = Number.parseFloat(`${booking.amount}`).toFixed(FRACTION_DIGITS)
            booking.otherPricingList = otherPricingList
            booking.otherPriceAmount = otherPriceAmount

            booking.discountMode = null
            booking.discountValue = null
            booking.netAmount = booking.amount
            booking.discountValueFromAPI = null
            booking.discountModeFromAPI = null
            booking.discountnetAmountFromAPI = null

            if (configs.discount.isEnable) {
                props.updateConfigBookingTicketModal({isLoading: true})

                // check for discounts
                const request = {appointmentDateTime: moment(`${booking.appointmentDate} ${booking.endTime}`, 'YYYY-MM-DD HH:mm').toISOString(), amount: Number(booking.amount)}
                const checkForDiscounts = await getDiscountsForService(shopId, selectedService.serviceId, [request])

                props.updateConfigBookingTicketModal({isLoading: false})

                if(_.size(checkForDiscounts?.data) > 0) {
                    const _discount = checkForDiscounts.data[0]
                    setDiscount(_discount)

                    booking.discountMode = _discount.discountType
                    booking.discountValue = Number(booking.amount) - Number(_discount.amount) + "/="
                    booking.netAmount =  Number(_discount.amount)
                    booking.discountValueFromAPI = Number(booking.amount) - Number(_discount.amount) + "/="
                    booking.discountModeFromAPI = _discount.discountType
                    booking.discountnetAmountFromAPI = Number(_discount.amount)
                }
            }

            if (_.isFunction(onClickAddBooking)) {
                onClickAddBooking(booking)
            }

            setBookingObject(booking)
        } else {
            setBookingObject(undefined)
            onClickAddBooking(undefined)
        }
    }

    useEffect(() => {
        if (moment(bookingDate).isValid() && selectedService && selectedFacility) {
            if (isLoadingBookingTicket) {
                startTimeRef.current.selectedItem = undefined
                endTimeRef.current.selectedItem = undefined
                bookingAreaRef.current.selectedItem = undefined
                setStartTimes([])
                setEndTimes([])
                setBookingAreas([])
                setIsCheckedFullDay(false)
            } else {
                const serviceTime = selectedService.serviceHour * 60 + selectedService.serviceMinute
                let startTimeArray = _.map(createStartTimeArray(bookingDate, shopTimeArr, serviceTime), time => buildTimeSlot(moment(time, 'h:mm a').format('HH:mm'), moment(time, 'h:mm a').add(serviceTime, 'minutes').format('HH:mm')))

                startTimeArray = _.map(startTimeArray, item => {
                    return {...item, time: moment(item.time, 'HH:mm').format('h:mm a')}
                })
                startTimeRef.current.selectedItem = _.find(startTimeArray, {status: 'Active'}) ? {..._.find(startTimeArray, {status: 'Active'})} : undefined

                if (startTimeRef.current.selectedItem) {
                    onsSelectStartTime()
                    setToggleStartTimes(!toggleStartTimes)
                    setStartTimes(startTimeArray)
                } else {
                    endTimeRef.current.selectedItem = undefined
                    setEndTimes([])
                    setStartTimes([])
                    setBookingAreas([])
                    setIsCheckedFullDay(false)
                }
            }
        }
    }, [bookingDate, selectedService, selectedFacility, availableBookings, cartListBookings.length])

    useEffect(() => {
        if (isPickEditBooking && !_.isEmpty(editBookingObject)) {
            const serviceTime = selectedService.serviceHour * 60 + selectedService.serviceMinute
            let startTimeArray = _.map(createStartTimeArray(bookingDate, shopTimeArr, serviceTime), time => buildTimeSlot(moment(time, 'h:mm a').format('HH:mm'), moment(time, 'h:mm a').add(serviceTime, 'minutes').format('HH:mm')))

            startTimeArray = _.map(startTimeArray, item => {
                return {...item, time: moment(item.time, 'HH:mm').format('h:mm a')}
            })

            const selectedStartTime = _.find(startTimeArray, {formattedTime: editBookingObject.startTime, status: 'Active'})
            if (selectedStartTime) {
                const endTimesList = createEndTimeArray(selectedStartTime.time)
                let endTime = _.get(endTimesList, '[0]', null)

                if (editBookingObject.isFullDay) {
                    endTime = _.last(endTimesList)
                } else {
                    const end = _.find(endTimesList, {formattedTime: editBookingObject.endTime})

                    if (end) {
                        endTime = end
                    }
                }

                startTimeRef.current.selectedItem = selectedStartTime
                endTimeRef.current.selectedItem = endTime

                if (endTime) {
                    const courts = getCourts(selectedStartTime.time, endTime.time)
                    const selectedCourt = _.find(courts, {halfCourtName: editBookingObject.courtName})

                    bookingAreaRef.current.selectedItem = selectedCourt ? selectedCourt: courts[0]
                    setStartTimes(startTimeArray)
                    setEndTimes(endTimesList)
                    setBookingAreas(courts)

                    if (editBookingObject.isFullDay) {
                        setIsCheckedFullDay(true)
                        onCreateBooking(bookingAreaRef.current.selectedItem.halfCourtName)
                        fullDayRef.current.checked = true
                    } else {
                        setToggleStartTimes(!toggleStartTimes)
                        setToggleEndTimes(!toggleEndTimes)
                        setToggleBookingAreas(!toggleBookingAreas)
                    }
                } else {
                    endTimeRef.current.selectedItem = undefined
                    setEndTimes([])
                    setStartTimes([])
                    setBookingAreas([])
                    setIsCheckedFullDay(false)
                }
            } else {
                startTimeRef.current.selectedItem = _.find(startTimeArray, {status: 'Active'}) ? {..._.find(startTimeArray, {status: 'Active'})} : undefined

                if (startTimeRef.current.selectedItem) {
                    onsSelectStartTime()
                    setToggleStartTimes(!toggleStartTimes)
                    setStartTimes(startTimeArray)
                } else {
                    endTimeRef.current.selectedItem = undefined
                    setEndTimes([])
                    setStartTimes([])
                    setBookingAreas([])
                    setIsCheckedFullDay(false)
                }
            }
        }
    }, [isPickEditBooking]);

    const hasFullDayBooking = useMemo(() => {
        if (_.size(startTimes) > 0 && bookingDate && selectedService && selectedFacility) {
            const startTime = _.first(startTimes).time
            const endTime = _.last(startTimes).time
            const timeSlot = buildTimeSlot(startTime, endTime)

            return timeSlot.status === 'Active' && _.find(getCourts(startTime, endTime), {halfCourtName: enumBookingArea.FULL})
        }

        return false
    }, [[startTimes, bookingDate, selectedService, selectedFacility, availableBookings, cartListBookings]])

    useEffect(() => {
        if (_.size(startTimes) > 0) {
            if (!isLoadingBookingTicket) {
                onCreateBooking(bookingAreaRef.current.selectedItem.halfCourtName)
            }
        } else {
            onCreateBooking()
        }
    }, [toggleBookingAreas]);

    return (<>
        {hasFullDayBooking && !isLoadingBookingTicket && <Row>
            <Col>
                <FormGroup className='pt-4 pb-4 px-4 border-top border-bottom d-flex justify-content-between align-items-center'>
                    <div>
                        <Input
                            onChange={ e => {
                            setIsCheckedFullDay(e.target.checked)
                            onCreateBooking(bookingAreaRef.current.selectedItem.halfCourtName)}}
                            checked={isCheckedFullDay}
                            innerRef={fullDayRef} type="checkbox" name="is_full_day" id="booking_ticket_full_day" />
                        <Label check for="full_day">Full Day Booking</Label>
                    </div>
                </FormGroup>
            </Col>
        </Row>}

        {!isCheckedFullDay && <Row>
                <Col md={4} sm={6} xs={6}>
                    <BookingDropdown
                        id='booking-ticket-start-times'
                        disables={isCheckedFullDay}
                        ref={startTimeRef}
                        label='Start Time'
                        displayLabel='time'
                        validateText='Please Select a Time'
                        toggleValidate={toggleStartTimes}
                        onSelectItem={() => onsSelectStartTime()}
                        required={true}
                        itemList={startTimes}/>
                </Col>
                <Col md={4} sm={6} xs={6}>
                    <BookingDropdown
                        id='booking-ticket-end-times'
                        ref={endTimeRef}
                        toggleValidate={toggleEndTimes}
                        label='End Time'
                        displayLabel='time'
                        validateText='Please Select a Time'
                        onSelectItem={() => onSelectEndTIme()}
                        required={true}
                        itemList={endTimes}/>
                </Col>
                <Col md={4}>
                    <BookingDropdown
                        id='booking-ticket-facilty-court-dropdown'
                        ref={bookingAreaRef}
                        toggleValidate={toggleBookingAreas}
                        label='Court'
                        displayLabel='displayBookingArea'
                        validateText='Please Select a Court'
                        onSelectItem={(item) => {
                            onCreateBooking(item.halfCourtName)
                        }}
                        required={true}
                        itemList={bookingAreas}/>
                </Col>
            </Row>}
        {!isLoadingBookingTicket && _.size(startTimes) > 0 && bookingObject && <Row>
            <Col>
                <p>
                    <span className='d-block'>{bookingObject.otherPriceAmount > 0 ? `Fixed Amount ${configs.currencyPrefix}: ${Number.parseFloat(`${bookingObject.amount - bookingObject.otherPriceAmount}`).toFixed(FRACTION_DIGITS)}` : `Amount ${configs.currencyPrefix}: ${bookingObject.amount}`}</span>
                    {bookingObject.otherPriceAmount > 0 && <>
                        {_.map(bookingObject.otherPricingList, (object, index) => {
                            return <>
                                <span className="pt-1 d-block">Additional Charging {index + 1}: {configs.currencyPrefix}{Number.parseFloat(object.charging).toFixed(FRACTION_DIGITS)}</span>
                                <span className="text-muted small d-block">({object.meta.description})</span>
                            </>
                        })}
                        {bookingObject?.otherPriceAmount > 0 && <h6 className="mt-2">Full Amount {configs.currencyPrefix}: {bookingObject.amount}</h6>}
                    </>}
                </p>
            </Col>
        </Row>}
        {!isLoadingBookingTicket && _.size(startTimes) === 0 && <Row><Col><Alert>Not available, Check another date</Alert></Col></Row>}
        {discount && <Row>
            <Col>
                <Label className="d-block pb-2">Discount: {(Number(bookingObject?.amount) - Number(discount.amount)) + "/="}</Label>
            </Col>
            </Row>}
        </>)
})

const getOtherPricing = (isAdditionalPricingSupport, additionalPricingList, appointmentDate, startTime, endTime, isFullDay) => {
    const additionalPricing = _.filter(additionalPricingList, {day: moment(appointmentDate).format('dddd'), status: 'Active'});
    const otherPriceArray = [];

    if (isAdditionalPricingSupport && additionalPricing.length > 0) {
        _.forEach(additionalPricing, function(obj) {
            const otherPriceObject = {
                serviceChargeMinutes: 0,
                otherTimeMinutes: 0,
                meta: {}
            }
            if (isFullDay) {
                otherPriceObject.otherTimeMinutes = moment(obj.endTime, 'HH:mm').diff(moment(obj.startTime, 'HH:mm'), 'minutes');
                otherPriceObject.meta = {...obj};
                otherPriceObject.serviceChargeMinutes = otherPriceObject.otherTimeMinutes;

                otherPriceArray.push(otherPriceObject);
            } else {
                const selectedTimeSlotStartTime = startTime;
                const selectedTimeSlotEndTime = endTime;
                const otherPriceStartTime = obj.startTime;
                const otherPriceEndTime = obj.endTime;
                const additionalPriceStartTime = moment(selectedTimeSlotStartTime, 'HH:mm').isBetween(moment(otherPriceStartTime, 'HH:mm'), moment(otherPriceEndTime, 'HH:mm'), 'minutes', '[]');
                const additionalPriceEndTime = moment(selectedTimeSlotEndTime, 'HH:mm').isBetween(moment(otherPriceStartTime, 'HH:mm'), moment(otherPriceEndTime, 'HH:mm'), 'minutes', '[]');
                const selectedTimeStart = moment(otherPriceStartTime, 'HH:mm').isBetween(moment(selectedTimeSlotStartTime, 'HH:mm'), moment(selectedTimeSlotEndTime, 'HH:mm'), 'minutes', '[]');
                const selectedTimeEnd = moment(otherPriceEndTime, 'HH:mm').isBetween(moment(selectedTimeSlotStartTime, 'HH:mm'), moment(selectedTimeSlotEndTime, 'HH:mm'), 'minutes', '[]');

                if (additionalPriceEndTime && additionalPriceStartTime) {
                    otherPriceObject.otherTimeMinutes = moment(selectedTimeSlotEndTime, 'HH:mm').diff(moment(selectedTimeSlotStartTime, 'HH:mm'), 'minutes');
                }else if (additionalPriceEndTime) {
                    otherPriceObject.otherTimeMinutes = moment(selectedTimeSlotEndTime, 'HH:mm').diff(moment(otherPriceStartTime, 'HH:mm'), 'minutes');
                } else if (additionalPriceStartTime) {
                    otherPriceObject.otherTimeMinutes = moment(otherPriceEndTime, 'HH:mm').diff(moment(selectedTimeSlotStartTime, 'HH:mm'), 'minutes');
                } else if (selectedTimeStart && selectedTimeEnd) {
                    otherPriceObject.otherTimeMinutes = moment(otherPriceEndTime, 'HH:mm').diff(moment(otherPriceStartTime, 'HH:mm'), 'minutes');
                }

                if (otherPriceObject.otherTimeMinutes) {
                    otherPriceObject.serviceChargeMinutes = moment(obj.endTime, 'HH:mm').diff(moment(obj.startTime, 'HH:mm'), 'minutes');
                    otherPriceObject.meta = {...obj};
                    otherPriceArray.push(otherPriceObject);
                }
            }
        });

        return otherPriceArray;
    }

    return otherPriceArray;
}

const createStartTimeArray = (bookingDate, shopTimeArr, serviceTime) => {
    const shopDay = _.find(shopTimeArr, {day: moment(bookingDate).format('dddd')}, null)
    const timeArray = []

    if (shopDay) {
        let openTime = moment(shopDay.openTime, 'HH:mm')
        const closeTime = moment(shopDay.closeTime, 'HH:mm')

        let lastTime = closeTime.clone().subtract(serviceTime, 'minutes')

        if (!isTimeSlotExpired(bookingDate, openTime.format('h:mm a'))) {
            timeArray.push(moment(openTime).format('h:mm a'))
        }

        while (openTime.isBefore(lastTime)) {
            const time = moment(openTime).add(TIME_SPLIT_VALUE, 'minutes').format('h:mm a')

            if (!isTimeSlotExpired(bookingDate, time)) {
                timeArray.push(time)
            }

            openTime = moment(openTime).add(TIME_SPLIT_VALUE, 'minutes');
        }

        return timeArray
    }

    return timeArray
}

const isTimeSlotClash = (TS, TE, RS, RE) => {
    const timeslotStart = moment(`01/01/2024 ${TS}`)
    const timeslotEnd = moment(`01/01/2024 ${TE}`)

    const rangeStart = moment(`01/01/2024 ${RS}`)
    const rangeEnd = moment(`01/01/2024 ${RE}`)

    // If timeslot interval of both are equal
    const case1 = (timeslotStart.format('HH:mm') === rangeStart.format('HH:mm') && timeslotEnd.format('HH:mm') === rangeEnd.format('HH:mm'))

    // If timeslot interval is less than the compared time range
    const case2 = (timeslotStart.isBetween(rangeStart, rangeEnd) || timeslotEnd.isBetween(rangeStart, rangeEnd))

    // If timeslot interval is larger than the compared time range
    const case3 = (rangeStart.isBetween(timeslotStart, timeslotEnd) || rangeEnd.isBetween(timeslotStart, timeslotEnd))

    return case1 || case2 || case3;
}

const creatBookingAreaObject = (area, count, uniqueId) => {
    const object = {displayBookingArea: 'Full Court', halfCourtName: 'FULL', status: 'Active', id: uniqueId ? uniqueId : _.uniqueId()}

    if (area === enumBookingArea.HALF) {
        object.displayBookingArea = `Share Court ${count}`
        object.halfCourtName = `${enumBookingArea.HALF}_${count}`
    }

    return object;
}

const isTimeSlotExpired = (selectedDate, TS) => {

    let currentDate = moment(new Date())

    if (currentDate.isSame(selectedDate, 'day')) {
        let timeslotStart = moment(`${moment(currentDate).format('YYYY/MM/DD')} ${TS}`)

        return timeslotStart.isBefore(currentDate);
    }
}

const mapStateToFacilityProps = (state) => {
    return {
        shopTimeArr: state.shop.shopTimeArr,
        holidays: state.shop.holidays,
        timeOffs: state.shop.timeOffs,
        isLoadingBookingTicket: state.baseComponents.bookingTicketReduxConfig.isLoading,
        shopId: state.shop.shopId,
        isEdit: state.baseComponents.bookingTicketReduxConfig.isEdit,
        editBookingObject: state.baseComponents.bookingTicketReduxConfig.editBookingObject,
        isPickEditBooking: state.baseComponents.bookingTicketReduxConfig.isPickEditBooking
    };
};

const SportFacilityTimeSelector = connect(mapStateToFacilityProps, {updateConfigBookingTicketModal}, null, {forwardRef: true})(SportTimer);

export default SportFacilityTimeSelector
