// TODO: Give a image url from this projects storage
export const DEFAULT_PROFILE_IMAGE = "https://s3-ap-southeast-1.amazonaws.com/onawadak-job-categories/man.png";
export const DEFAULT_SALON_SERVICE_ICON = "https://firebasestorage.googleapis.com/v0/b/bookezy-dev.appspot.com/o/shop%2FTNS_Business%2FTNS_Business_u1724826691908.png?alt=media"; // This needs to be updated with the correct link
export const DEFAULT_SPORT_ICON = "https://firebasestorage.googleapis.com/v0/b/bookezy-dev.appspot.com/o/assets%2Fsports.jpg?alt=media&token=3dff1985-2d3c-481c-81a9-f3c269f0f3ca";
export const DEFAULT_SPORT_GROUND_ICON = "https://firebasestorage.googleapis.com/v0/b/bookezy-dev.appspot.com/o/assets%2Fsports-ground.jpg?alt=media&token=d9830175-c94a-4aa7-bc93-97472664e2de";

// Per page sizes
export const SERVICES_PAGE_SIZE = 10
export const EMPLOYEES_PAGE_SIZE = 10
export const FACILITIES_PAGE_SIZE = 10
export const CUSTOMER_PAGE_SIZE = 10
export const BRANCHES_PAGE_SIZE = 12
