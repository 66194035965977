import axios from "axios";
import { configs } from "../utils/configs";
const baseURL = configs.baseURL;
const apiEndPoint = `${baseURL}/b4u-core`;

export const createDiscount = async (data) => {
  try {
    const configs = {
      headers: {
        Authorization: window.localStorage.getItem("loginToken"),
      },
    };

    return await axios.post(`${apiEndPoint}/discounts/add`, data, configs);
  } catch (e) {
    return e;
  }
};

export const updateDiscount = async (data, discountId) => {
  try {
    const configs = {
      headers: {
        Authorization: window.localStorage.getItem("loginToken"),
      },
    };

    return await axios.put(
      `${apiEndPoint}/discounts/update/${discountId}`,
      data,
      configs
    );
  } catch (e) {
    return e;
  }
};

// discount list for shop
export const getDiscountList = async (shopId, page, size) => {
  try {
    const configs = {
      headers: {
        Authorization: window.localStorage.getItem("loginToken"),
      },
    };

    return await axios.get(
      `${apiEndPoint}/discounts/search/${shopId}?page=${page}&size=${size}`,
      configs
    );
  } catch (e) {
    return e;
  }
};

export const getDiscountAmountByServiceId = async (serviceId, amount) => {
  try {
    const configs = {
      headers: {
        Authorization: window.localStorage.getItem("loginToken"),
      },
    };

    return await axios.get(
      `${apiEndPoint}/discounts/service/${serviceId}/${amount}`,
      configs
    );
  } catch (e) {
    return e;
  }
};

// get discount when add service to appointment
export const getDiscountForAddService = async (serviceId) => {
  try {
    const configs = {
      headers: {
        Authorization: window.localStorage.getItem("loginToken"),
      },
    };

    // return await axios.get(`${baseURL}/discounts/add${serviceId}`, configs);
  } catch (e) {
    return e;
  }
};

// get discounts for bind to service
export const getDiscountsForService = async (shopId, serviceId, requestDateTimeList) => {
  try {
    const configs = {
      headers: {
        Authorization: window.localStorage.getItem("loginToken"),
      },
    };

    return await axios.post(
      `${apiEndPoint}/discounts/${shopId}/service/${serviceId}/validate`, requestDateTimeList, configs);
  } catch (e) {
    return e;
  }
};

export const getDiscountPlan = async (planId) => {
  try {
    const configs = {
      headers: {
        Authorization: window.localStorage.getItem("loginToken"),
      },
    };

    return await axios.get(`${apiEndPoint}/discounts/get/${planId}`, configs);
  } catch (e) {
    return e;
  }
};

export const discountUpdatePriority = async (shopId, obj) => {
  try {
    const configs = {
      headers: {
        Authorization: window.localStorage.getItem("loginToken"),
      },
    };

    return await axios.put(
      `${apiEndPoint}/discounts/update/list/priority/${shopId}`,
      obj,
      configs
    );
  } catch (e) {
    return e;
  }
};
