import { toast } from 'react-toastify'
import * as _ from 'lodash'
import { configs } from './configs'
import moment from 'moment'


let localNumber = ''
let globalNumber = ''
const WEEK_MAP = { Sunday: 0, Monday: 1, Tuesday: 2, Wednesday: 3, Thursday: 4, Friday: 5, Saturday: 6 }

export const notify = (message, type, props = {}) => {
    toast(message, { type: toast.TYPE[type], className: 'custom-toast', ...props })
};

export const convertToGlobalNumber = (countryCode, localNumber) => {
    globalNumber = countryCode + localNumber.slice(1, localNumber.length)
    return globalNumber
}

export const convertToLocalNumber = (globalNumber) => {
    localNumber = "0" + globalNumber.slice(2, globalNumber.length)
    return localNumber
}

export const checkPhoneNumber = (text) => {
    let regex = configs.phoneRegex
    let isValid = regex.test(text)
    return isValid
}

export const checkSettingsPhoneNumber = (text) => {
    let regex = configs.settingsPhoneRegex
    let isValid = regex.test(text)
    return isValid
}

export const checkLandPhoneNumber = (text) => {
    let regex = configs.landPhoneRegex
    let isValid = regex.test(text)
    return isValid
}

export const checkSiteName = () => {
    switch (configs.country) {
        case 'LK':
            return "Bookezy"
        case 'AU':
            return "Bookezy"
        case 'CAN':
            return 'Bookezy'
    }
}

export const isSuperAdmin = () => {
    return localStorage.getItem("role") === "SuperAdmin"
}

export const isAdmin = () => {
    return localStorage.getItem("role") === "Admin"
}

export const isEmployee = () => {
    return localStorage.getItem("role") === "Employee"
}

export const getUserId = () => {
    return localStorage.getItem("userId");
}

export const selectUserRole = () => {
    let userRole = ''
    switch (localStorage.getItem('role')) {
        case 'SuperAdmin':
            userRole = 'Super Admin'
            break;
        case 'Admin':
            userRole = 'Admin'
            break;
        case 'Employee':
            userRole = 'Employee'
            break;
        default:
            userRole = ''
    }
    return userRole
}

export const getDisabledCalendarDates = (id, leaves, openingHours, holidays) => {
    let dates = []
    let before = { before: new Date() }    // Disable days before today
    let daysOfWeek = []     // Array of numbers corresponding to the day of the week (Ex: sunday is 0, saturday is 6)

    _.forEach(openingHours, (value, key) => {
        if (value.status === "Inactive") {
            daysOfWeek.push(WEEK_MAP[value.day])
        }
    })

    let shopClosedDates = { daysOfWeek: daysOfWeek }

    _.forEach(leaves, (obj) => {
        if (id === obj.employeeId && _.isEmpty(obj.startTime)) {
            dates.push(new Date(obj.leaveDate))    // leave dates
        }
        if (id === obj.facilityId && _.isEmpty(obj.startTime)) {
            dates.push(new Date(obj.facilityOffDate))    // leave dates for facility
        }
    })

    _.forEach(holidays, (obj) => {
        if (_.isEmpty(obj.startTime)) {
            dates.push(new Date(obj.holiday))    // shop holiday dates
        }
    })

    dates.push(shopClosedDates)
    dates.push(before)
    return dates;
}

export const isValidLeave = (leave, openingHours) => {
    let isValid = true;
    if (leave.startTime === leave.endTime) {
        isValid = false;
    }
    else {
        _.forEach(openingHours, (value, key) => {
            if (value.day === moment(leave.Date).format("dddd")) {
                if (!(leave.startTime >= value.openTime && leave.endTime <= value.closeTime)) {
                    isValid = false;
                }
            }
        })
    }
    return isValid;
}

export const APPOINTMENT_STATUS = {
    ACTIVE: {code: 'Active', displayText: 'Active'},
    INACTIVE: {code: 'Inactive', displayText: 'Inactive'},
    PENDING: {code: 'Pending', displayText: 'Pending'},
    PENDING_PAYMENT: {code: 'Pending_Payment', displayText: 'Pending Payment'}
}

export const APPOINTMENT_TYPE = {
    SINGLE: "SINGLE",
    MULTIPLE: "MULTIPLE",
    RECURRING: "RECURRING"
}

export const BUSINESS_TYPE = {
    SALON: 1,
    LEARNERS: 2,
    SPORTS_CLUB: 3,
    SPA_WELLNESS: 4
}
