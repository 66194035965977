import {
    CONFIG_ADD_APPOINTMENT_MODAL,
    TOGGLE_ADD_APPOINTMENT_MODAL,
    TOGGLE_BOOKING_TICKET_MODAL,
    UPDATE_CONFIG_BOOKING_TICKET_MODAL
} from "../actions/types";

const INITIAL_STATE = {
    addAppointmentModalConfig: {
        isOpen: false,
        firstName: '',
        lastName: '',
        email: '',
        mobileNo: '',
        gender: '',
        isDisabledCustomerfirstName: false,
        isDisabledCustomerlastName: false,
        isDisabledCustomeremail: false,
        isDisabledCustomermobileNo: false,
        isDisabledCustomergender: false,

    },
    bookingTicketReduxConfig: {
        isOpenCustomerSearchModal: false,
        isOpenModal: false,
        isEdit: false,
        isLoading: false,
        bookings: [],
        editBookingObject: {},
        isPickEditBooking: false,
        isFetchingAppointments: false,
        userDetails: {}
    }
}
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TOGGLE_ADD_APPOINTMENT_MODAL:
            const isOpen = !state.addAppointmentModalConfig.isOpen

            return {
                ...state,
                addAppointmentModalConfig: {...INITIAL_STATE.addAppointmentModalConfig, isOpen}
            }
        case CONFIG_ADD_APPOINTMENT_MODAL:
            const configurations = action.payload.configuration

            return {
                ...state,
                addAppointmentModalConfig: {...state.addAppointmentModalConfig, ...configurations}
            }
        case TOGGLE_BOOKING_TICKET_MODAL:
            const {ticketConfiguration} = action.payload

            return {
                ...state,
                bookingTicketReduxConfig: {...INITIAL_STATE.bookingTicketReduxConfig, ...ticketConfiguration}
            }
        case UPDATE_CONFIG_BOOKING_TICKET_MODAL:
            const {configuration} = action.payload

            return {
                ...state,
                bookingTicketReduxConfig: {...state.bookingTicketReduxConfig, ...configuration}
            }
        default:
            return state
    }
}
