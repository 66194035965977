import {Col, FormGroup, Label, Row} from "reactstrap";
import BookingDropdown from "../BookingDropdown";
import React, {useEffect, useState} from "react";
import DatePicker from "reactstrap-date-picker/lib/DatePicker";
import {connect} from "react-redux";
import moment from "moment";
import * as _ from "lodash";
import {updateConfigBookingTicketModal} from "../../../actions/BaseComponentsActions";
import {getDiscountsForService} from "../../../api/discountsApi";
import {configs} from "../../../utils/configs";

const FRACTION_DIGITS = 2

const frequencyOptions = [
    {value: 'Daily', key: 'Daily', status: 'Active'},
    {value: 'Weekly', key: 'Weekly', status: 'Active'},
    {value: 'Monthly', key: 'Monthly', status: 'Active'}]

function RecurringPanel(props) {
    const {recurringDate, recurringBooking, shopTimeArr, selectedService, onCallBack} = props
    const [frequency, setFrequency] = useState()
    const [date, setDate] = useState()

    useEffect(() => {
        if (frequency) {
            setDate(minDate())
        }
    }, [frequency]);

    useEffect(() => {
        if (frequency && date && moment(date).isAfter(new Date())) {
            onCreateRecurringBookings()
        }
    }, [date]);

    const onCreateRecurringBookings = async () => {
        let recurringDates = []

        if (frequency.key === 'Monthly') {
            let done = false
            let day = moment(recurringDate, "YYYY-MM-DD").format('D')
            let currantAppointmentDate = moment(recurringDate, "YYYY-MM-DD")

            recurringDates.push(currantAppointmentDate.format('YYYY-MM-DD'));

            while (!done) {
                let nextYearMonth = currantAppointmentDate
                    .add(1, 'months')
                    .format('YYYY-MM');

                let nextMonthDate = moment(nextYearMonth + '-' + day);
                let ed = moment(date)

                // Check if the day is invalid (e.g., February 31st, April 31st)
                if (!nextMonthDate.isValid()) {
                    nextYearMonth = currantAppointmentDate.add(1, 'months').format('YYYY-MM');
                    nextMonthDate = moment(nextYearMonth + '-' + day);
                }

                if (ed.isSameOrAfter(nextMonthDate)) {
                    recurringDates.push(nextMonthDate.format("YYYY-MM-DD"));
                } else {
                    done = true;
                }
            }

        } else {
            let recurrence = moment().recur(moment(recurringDate).format('YYYY-MM-DD'), moment(date).add(1, 'd').format('YYYY-MM-DD')).every(frequency.key === 'Daily' ? 1 : 7).days();
            recurringDates = recurrence.all("YYYY-MM-DD");
        }

        const dateTimeList = []

        let newAppointments = recurringDates.map((d) => {
            const newBooking = {...recurringBooking, appointmentDate: d}

            if (newBooking.isFullDay) {
                const shopDay = _.find(shopTimeArr, {day: moment(d).format('dddd')}, null)

                if (shopDay) {
                    newBooking.startTime = shopDay.openTime
                    newBooking.endTime = shopDay.closeTime
                }

                const duration = moment(`01/01/2024 ${newBooking.endTime}`).diff(moment(`01/01/2024 ${newBooking.startTime}`), 'minutes');
                const serviceTime = (selectedService.serviceHour * 60) + selectedService.serviceMinute;

                newBooking.amount = selectedService.fullDayPrice > 0 ? Number(`${selectedService.fullDayPrice}`) : Number(`${((Number(selectedService.serviceFullPrice) / serviceTime) * Number(duration))}`)
            } else {
                newBooking.amount = newBooking.isPartial && selectedService.servicePartialPrice > 0 ? newBooking.facilityPortionPrice * newBooking.customizedSlots : newBooking.customizedSlots * selectedService.serviceFullPrice
            }

            const appointmentDateTime = moment(`${d} ${recurringBooking.endTime}`, 'YYYY-MM-DD HH:mm').toISOString()
            let otherPricingList = getOtherPricing(_.get(selectedService, 'isAdditionalPricingSupport', false), _.get(selectedService, 'additionalPricingList', []), newBooking.appointmentDate, newBooking.startTime, newBooking.endTime, newBooking.isFullDay)
            let otherPriceAmount = 0

            newBooking.appointmentDateTime = appointmentDateTime

            if (_.size(otherPricingList) > 0) {
                const updatedPriceList = _.map(otherPricingList, (obj) => {
                    // const charging = Number(booking.isFullDay ? obj.meta.fullPrice : (obj.otherTimeMinutes * (((booking.isPartial && obj.meta.partialPrice) ? obj.meta.partialPrice : obj.meta.fullPrice) / obj.serviceChargeMinutes)))
                    const charging = Number(newBooking.isFullDay ? obj.meta.fullPrice : (obj.otherTimeMinutes * ((newBooking.isPartial ? (_.isNaN(obj.meta.partialPrice) ? 0 : obj.meta.partialPrice) : (_.isNaN(obj.meta.fullPrice) ? 0 : obj.meta.fullPrice)) / obj.serviceChargeMinutes)))
                    otherPriceAmount = otherPriceAmount + charging

                    return {...obj, charging}
                });

                newBooking.amount = newBooking.amount + otherPriceAmount;
                newBooking.additionalPricingListIds = _.map(otherPricingList, obj => obj.meta.id)
                otherPricingList = updatedPriceList
            }

            newBooking.amount = Number.parseFloat(`${newBooking.amount}`).toFixed(FRACTION_DIGITS)
            newBooking.otherPricingList = otherPricingList
            newBooking.otherPriceAmount = otherPriceAmount

            dateTimeList.push({appointmentDateTime, amount: Number(newBooking.amount)})

            return newBooking
        })

        props.updateConfigBookingTicketModal({isLoading: true})

        if (configs.discount.isEnable) {
            const response = await getDiscountsForService(recurringBooking.shopId, selectedService.serviceId, dateTimeList)

            if (_.size(response?.data) > 0) {
                _.forEach(response.data, obj => {
                    const appointment = _.find(newAppointments, item => moment(obj.appointmentDateTime).format('YYYY-MM-DD') === moment(item.appointmentDateTime).format('YYYY-MM-DD'))

                    if (appointment) {
                        appointment.discountMode = obj.discountType
                        appointment.discountValue =  Number(appointment.amount) - Number(obj.amount) + "/="
                        appointment.netAmount = Number(obj.amount)
                        appointment.discountValueFromAPI =  Number(appointment.amount) - Number(obj.amount) + "/="
                        appointment.discountModeFromAPI = obj.discountType
                        appointment.discountnetAmountFromAPI =  Number(obj.amount)
                    }
                })
            }
        }

        props.updateConfigBookingTicketModal({isLoading: false})
        onCallBack(newAppointments)
    }

    const minDate = () => {
        return moment(new Date(recurringDate)).add(1, frequency?.key === 'Monthly' ? 'M' : frequency?.key === 'Weekly' ? 'w' : 'd').toISOString()
    }

    return (<>
        <Row>
            <Col md={6}>
                <BookingDropdown
                    id='booking-ticket-recurring-frequency'
                    label='Frequency'
                    displayLabel='value'
                    validateText='Please Select a Frequency'
                    onSelectItem={setFrequency}
                    required={true}
                    itemList={frequencyOptions}/>
            </Col>
            <Col md={6}>
                {frequency && <FormGroup>
                    <Label ><span className="required">*</span>End Date</Label>
                    <DatePicker
                        id="booking-ticket-recurance-end"
                        minDate={minDate()}
                        value={date}
                        onChange={(v, f) => {
                            setDate(moment(new Date(v)).format('YYYY-MM-DD'))
                        }}
                        autoFocus={true}
                    />
                </FormGroup>}
            </Col>
        </Row>
    </>)
}

const getOtherPricing = (isAdditionalPricingSupport, additionalPricingList, appointmentDate, startTime, endTime, isFullDay) => {
    const additionalPricing = _.filter(additionalPricingList, {day: moment(appointmentDate).format('dddd'), status: 'Active'});
    const otherPriceArray = [];

    if (isAdditionalPricingSupport && additionalPricing.length > 0) {
        _.forEach(additionalPricing, function(obj) {
            const otherPriceObject = {
                serviceChargeMinutes: 0,
                otherTimeMinutes: 0,
                meta: {}
            }
            if (isFullDay) {
                otherPriceObject.otherTimeMinutes = moment(obj.endTime, 'HH:mm').diff(moment(obj.startTime, 'HH:mm'), 'minutes');
                otherPriceObject.meta = {...obj};
                otherPriceObject.serviceChargeMinutes = otherPriceObject.otherTimeMinutes;

                otherPriceArray.push(otherPriceObject);
            } else {
                const selectedTimeSlotStartTime = startTime;
                const selectedTimeSlotEndTime = endTime;
                const otherPriceStartTime = obj.startTime;
                const otherPriceEndTime = obj.endTime;
                const additionalPriceStartTime = moment(selectedTimeSlotStartTime, 'HH:mm').isBetween(moment(otherPriceStartTime, 'HH:mm'), moment(otherPriceEndTime, 'HH:mm'), 'minutes', '[]');
                const additionalPriceEndTime = moment(selectedTimeSlotEndTime, 'HH:mm').isBetween(moment(otherPriceStartTime, 'HH:mm'), moment(otherPriceEndTime, 'HH:mm'), 'minutes', '[]');
                const selectedTimeStart = moment(otherPriceStartTime, 'HH:mm').isBetween(moment(selectedTimeSlotStartTime, 'HH:mm'), moment(selectedTimeSlotEndTime, 'HH:mm'), 'minutes', '[]');
                const selectedTimeEnd = moment(otherPriceEndTime, 'HH:mm').isBetween(moment(selectedTimeSlotStartTime, 'HH:mm'), moment(selectedTimeSlotEndTime, 'HH:mm'), 'minutes', '[]');

                if (additionalPriceEndTime && additionalPriceStartTime) {
                    otherPriceObject.otherTimeMinutes = moment(selectedTimeSlotEndTime, 'HH:mm').diff(moment(selectedTimeSlotStartTime, 'HH:mm'), 'minutes');
                }else if (additionalPriceEndTime) {
                    otherPriceObject.otherTimeMinutes = moment(selectedTimeSlotEndTime, 'HH:mm').diff(moment(otherPriceStartTime, 'HH:mm'), 'minutes');
                } else if (additionalPriceStartTime) {
                    otherPriceObject.otherTimeMinutes = moment(otherPriceEndTime, 'HH:mm').diff(moment(selectedTimeSlotStartTime, 'HH:mm'), 'minutes');
                } else if (selectedTimeStart && selectedTimeEnd) {
                    otherPriceObject.otherTimeMinutes = moment(otherPriceEndTime, 'HH:mm').diff(moment(otherPriceStartTime, 'HH:mm'), 'minutes');
                }

                if (otherPriceObject.otherTimeMinutes) {
                    otherPriceObject.serviceChargeMinutes = moment(obj.endTime, 'HH:mm').diff(moment(obj.startTime, 'HH:mm'), 'minutes');
                    otherPriceObject.meta = {...obj};
                    otherPriceArray.push(otherPriceObject);
                }
            }
        });

        return otherPriceArray;
    }

    return otherPriceArray;
}

const mapStateToFacilityProps = (state) => {
    return {
        businessTypeId: state.shop.businessTypeId,
        shopTimeArr: state.shop.shopTimeArr,
        leaves: state.shop.leaves,
        holidays: state.shop.holidays,
        timeOffs: state.shop.timeOffs
    };
};

const SportFacilityRecurring = connect(mapStateToFacilityProps, {updateConfigBookingTicketModal})(RecurringPanel);

export default SportFacilityRecurring
