import { getDiscountList, getDiscountPlan } from "./../api/discountsApi";
import {
  GET_DISCOUNT_PLAN,
  GET_DISCOUNT_PLAN_ERROR,
  GET_DISCOUNT_PLAN_SUCCESS,
  GET_DISCOUNT_PLANS,
  GET_DISCOUNT_PLANS_ERROR,
  GET_DISCOUNT_PLANS_SUCCESS,
  SELECT_DISCOUNT_PLAN,
  SHOW_UPDATE_DISCOUNT_MODAL,
} from "../actions/types";

export const getDiscountListAction = (shopId, page = 0, size = 10) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_DISCOUNT_PLANS });

      let discountList = null;
      try {
        discountList = await getDiscountList(shopId, page, size);
      } catch (networkError) {
        console.log("network error", networkError);
      }

      if (discountList) {
        dispatch({
          type: GET_DISCOUNT_PLANS_SUCCESS,
          payload: discountList,
        });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_DISCOUNT_PLANS_ERROR });
    }
  };
};

export const getDiscountPlanAction = (planId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_DISCOUNT_PLAN });

      let discountPlan = null;
      try {
        discountPlan = await getDiscountPlan(planId);
      } catch (networkError) {
        console.log("network error", networkError);
      }

      if (discountPlan) {
        dispatch({
          type: GET_DISCOUNT_PLAN_SUCCESS,
          payload: discountPlan,
        });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_DISCOUNT_PLAN_ERROR });
    }
  };
};

export const selectDiscountPlanAction = (plan) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: SELECT_DISCOUNT_PLAN,
        payload: plan,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const toggleShowUpdateDiscountModalAction = (state) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: SHOW_UPDATE_DISCOUNT_MODAL,
        payload: state,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
