import {connect} from "react-redux";
import React, {forwardRef, memo, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {
    Alert,
    Button,
    Col,
    CustomInput,
    Form, FormGroup, Input, Label,
    ModalBody, ModalFooter,
    Row, Spinner
} from "reactstrap";
import * as _ from "lodash";
import TextareaAutosize from "react-textarea-autosize";
import confirm from "reactstrap-confirm";
import moment from "moment/moment";
import {toast} from "react-toastify";
import { FormText } from "react-bootstrap";

import UserDetails from "../UserDetails";
import BookingTypeButtonGroup from "../BookingTypeButtonGroup";
import {APPOINTMENT_STATUS, APPOINTMENT_TYPE, notify} from "../../../utils/common";
import {toggleBookingTicketModal, updateConfigBookingTicketModal} from "../../../actions/BaseComponentsActions";
import BookingDropdown from "../BookingDropdown";
import BookingTicketDate from "../BookingTicketDate";
import {searchAppointments, checkFacilityPriority} from "../../../api/api";
import SportFacilityTimeSelector from "./SportFacilityTimeSelector";
import BookingTicketCart from "../BookingTicketCart";
import FacilityGuest from "./FacilityGuest";
import SportFacilityRecurring from "./SportFacilityRecurring";
import {submitAppointment, confirmAppointment, updateAppointment} from "../../../actions";
import {configs} from "../../../utils/configs";
import {uniqueId} from "lodash";

const DEFAULT_MODAL_STATE = {
    bookingType: APPOINTMENT_TYPE.MULTIPLE,
    isValidUserDetails: undefined
}

const SportFacilityComp = memo(forwardRef(function(props, ref) {
    const {facilities, sports, isLoadingBookingTicket, selectedBranch, defaultBranch, toggleBookingTicketModal, updateConfigBookingTicketModal, useDetails, confirmAppointment, submitAppointment, isEdit, editBookingObject} = props

    const [bookingType, setBookingType] = useState(APPOINTMENT_TYPE.MULTIPLE)
    const modalStateRef = useRef({...DEFAULT_MODAL_STATE})
    const [toggleUserDetailsValidate, setToggleUserDetailsValidate] = useState()

    const [selectedService, setSelectedService] = useState()
    const serviceRef = useRef({isValid: undefined, selectedItem: undefined})
    const [toggleValidateService, setToggleValidateService] = useState()

    const [selectedFacility, setSelectedFacility] = useState()
    const facilityRef = useRef({isValid: undefined, selectedItem: undefined})
    const [toggleValidateFacility, setToggleValidateFacility] = useState()

    const [bookingDate, setBookingDate] = useState()
    const dateRef = useRef({isValid: undefined, date: undefined})
    const [toggleValidateDate, setToggleValidateDate] = useState()

    const [currentBooking, setCurrentBooking] = useState()
    const [guestList, setGuestList] = useState([])

    const currentBookingsRef = useRef({bookings: []})
    const [cartBookings, setCartBookings] = useState([])
    const [isBulkDiscountActive, setIsBulkDiscountActive] = useState(false);
    const [bulkDiscountAmount, setBulkDiscountAmount] = useState(null);
    const [showPriorityCheckMsg, setShowPriorityCheckMsg] = useState(false);

    const isEnableRecurringDateSelecting = useMemo(() => bookingType === APPOINTMENT_TYPE.RECURRING && _.size(cartBookings) > 0,
        [bookingType, cartBookings])

    const specialNotesRef = useRef()

    const branchId = useMemo(() => (!_.isEmpty(selectedBranch) ? selectedBranch : defaultBranch)['branchId'], [selectedBranch, defaultBranch])

    const sportsMemo = useMemo(() => {
        return _.filter(sports, obj => _.size(obj.facilityId) > 0 && obj.status === 'Active')
    }, [sports])

    const filteredFacilities = useMemo(() => {
        const serviceRelatedFacility = []

        if (_.size(_.get(selectedService, 'facilityId', [])) > 0) {
            _.forEach(selectedService.facilityId, (id) => {
                const facility = _.find(facilities, {facilityId: id})

                if (facility) {
                    serviceRelatedFacility.push(facility)
                }
            })
        }

        let defaultFacility = serviceRelatedFacility.length > 0 ? serviceRelatedFacility[0] : undefined

        if (isEdit) {
            const selectedFacilityObj = _.find(serviceRelatedFacility, {facilityId: editBookingObject?.facilityId})

            defaultFacility = selectedFacilityObj ? selectedFacilityObj : serviceRelatedFacility.length > 0 ? serviceRelatedFacility[0] : undefined
        }

        facilityRef.current.selectedItem = defaultFacility
        facilityRef.current.isValid = !!defaultFacility
        setSelectedFacility(defaultFacility)
        setToggleValidateFacility(!toggleValidateFacility)
        return serviceRelatedFacility;
    }, [selectedService])

    useEffect( () => {
        if (bookingDate && selectedService && selectedFacility) {
            setShowPriorityCheckMsg(false)
            updateConfigBookingTicketModal({isLoading: true})
            onPickBookingDate().then(result => {
                if (result?.data?.data?.pageList) {
                    let list = _.filter(result.data.data.pageList, item => item.status === APPOINTMENT_STATUS.PENDING.code || item.status === APPOINTMENT_STATUS.PENDING_PAYMENT.code || item.status === APPOINTMENT_STATUS.ACTIVE.code)

                    if (isEdit) {
                        list = _.filter(list, obj => obj.id !== editBookingObject.id)

                        if (!editBookingObject.isPickEditBooking && specialNotesRef?.current) {
                            specialNotesRef.current.value = _.get(editBookingObject, 'specialNote', '')
                        }
                    }
                    currentBookingsRef.current.bookings = list
                } else {
                    currentBookingsRef.current.bookings = []
                }
            }).finally(() => {
                updateConfigBookingTicketModal({isLoading: false, isPickEditBooking: isEdit})
            })
        }
    }, [bookingDate, selectedFacility, selectedService]);

    useEffect(() => {
        if (!_.isEmpty(currentBooking)) {
            if (currentBooking.startTime || currentBooking.endTime) {
                setShowPriorityCheckMsg(false)
            }
        }
    }, [currentBooking]);

    useEffect(() => {
        if (isEdit && editBookingObject?.id) {
            const selectedSport = _.find(sportsMemo, {serviceId: editBookingObject.serviceId})
            const selectedDate = moment(editBookingObject.appointmentDate).format('YYYY-MM-DD')

            serviceRef.current.selectedItem = selectedSport
            serviceRef.current.isValid = true

            setGuestList(editBookingObject.guests)
            setSelectedService(selectedSport)
            setToggleValidateService(!toggleValidateService)
            setBookingDate(selectedDate)
        }
    }, [isEdit, editBookingObject]);

    useEffect(() => {
        if (_.size(cartBookings) > 0) {
            const updateList = _.map(cartBookings, (booking) => calculateBookingDiscount(booking))

            setCartBookings(updateList)
        }
    }, [isBulkDiscountActive, bulkDiscountAmount]);

    const calculateBookingDiscount = (booking) => {
        const discountValue = isBulkDiscountActive && _.isNumber(bulkDiscountAmount) ? `${bulkDiscountAmount}%` : booking.discountValueFromAPI
        const discountMode = isBulkDiscountActive && _.isNumber(bulkDiscountAmount) ? 'PERCENTAGE' : booking.discountModeFromAPI
        const netAmount = isBulkDiscountActive && _.isNumber(bulkDiscountAmount) ? (booking.amount - (booking.amount * (bulkDiscountAmount/100))) : _.isNumber(booking.discountnetAmountFromAPI) ? booking.discountnetAmountFromAPI : booking.amount

        return {...booking, discountValue, discountMode, netAmount}
    }

    const isValidate = () => modalStateRef.current.isValidUserDetails && facilityRef.current.isValid && serviceRef.current.isValid && dateRef.current.isValid;

    const onValidate = () => {
        if (!modalStateRef.current.isValidUserDetails) {
            modalStateRef.current.isValidUserDetails = false
            ref.current.scrollTop = 0
            setToggleUserDetailsValidate(!toggleUserDetailsValidate)
        }

        if (!facilityRef.current.isValid) {
            facilityRef.current.isValid = false
            setToggleValidateFacility(!toggleValidateFacility)
        }

        if (!serviceRef.current.isValid) {
            serviceRef.current.isValid = false
            setToggleValidateService(!toggleValidateService)
        }

        if (!dateRef.current.isValid) {
            dateRef.current.isValid = false
            setToggleValidateDate(!toggleValidateDate)
        }
    }

    const onSaveToCart = async () => {
        if (isValidate()) {
            if (currentBooking) {
                const cloneBooking = {...currentBooking}
                const requestObj = {
                    uniqueId: cloneBooking.uniqueId,
                    appointmentDate: cloneBooking.appointmentDate,
                    bookedPortionCount: cloneBooking.bookedPortionCount,
                    branchId: cloneBooking.branchId,
                    endTime: cloneBooking.endTime,
                    facilityId: cloneBooking.facilityId,
                    fullDay: cloneBooking.isFullDay,
                    partial: cloneBooking.isPartial,
                    serviceId: cloneBooking.serviceId,
                    startTime: cloneBooking.startTime,
                    facilityPortionCount: cloneBooking.facilityPortionCount
                }

                const exitCartBookings = _.map(cartBookings, obj => {
                    return {
                        uniqueId: obj.uniqueId,
                        appointmentDate: obj.appointmentDate,
                        bookedPortionCount: obj.bookedPortionCount,
                        branchId: obj.branchId,
                        endTime: obj.endTime,
                        facilityId: obj.facilityId,
                        fullDay: obj.isFullDay,
                        partial: obj.isPartial,
                        serviceId: obj.serviceId,
                        startTime: obj.startTime,
                        facilityPortionCount: obj.facilityPortionCount
                    }
                })

                exitCartBookings.push(requestObj)

                await onCheckPriority(exitCartBookings, cloneBooking.uniqueId, () => {
                    if (selectedService.minNoOfGuests > 0 && guestList.length > 0) {
                        cloneBooking.guests = guestList
                    }

                    cloneBooking.specialNote = specialNotesRef.current.value

                    setTimeout(() => {
                        ref.current.scrollTop = ref.current.scrollHeight
                    }, 500)

                    specialNotesRef.current.value = ''
                    setCartBookings([...cartBookings, calculateBookingDiscount(cloneBooking)])
                    setGuestList([])
                    setCurrentBooking(undefined)
                    setShowPriorityCheckMsg(false)
                }, () => {
                    setShowPriorityCheckMsg(true)
                    setTimeout(() => {
                        ref.current.scrollTop = ref.current.scrollHeight
                    }, 500)
                })
            }
        } else {
            onValidate()
        }
    }

    const onPickBookingDate = async (date) => {
        const facilityId = selectedFacility.facilityId
        // const serviceId = selectedService.serviceId
        const appointmentDate = bookingDate;
        return await searchAppointments({branchId, facilityId, appointmentDate})
    }

    const removeBookingFromCart = async (index) => {
        if (_.size(cartBookings) === 1) {
            const newList = [...cartBookings];
            newList.splice(index, 1);

            setCartBookings(newList)
        } else {
            const checkId = _.get(cartBookings, `[${index}].uniqueId`, null)
            const newList = [...cartBookings];
            newList.splice(index, 1);

            await onCheckPriority(_.map(newList, (booking) => {
                return {
                    uniqueId: booking.uniqueId,
                    appointmentDate: booking.appointmentDate,
                    bookedPortionCount: booking.bookedPortionCount,
                    branchId: booking.branchId,
                    endTime: booking.endTime,
                    facilityId: booking.facilityId,
                    fullDay: booking.isFullDay,
                    partial: booking.isPartial,
                    serviceId: booking.serviceId,
                    startTime: booking.startTime,
                    facilityPortionCount: booking.facilityPortionCount
                }
            }), checkId, () => {
                const newList2 = [...cartBookings];
                newList2.splice(index, 1);

                setCartBookings(newList2)
            }, () => {
                toast(`Unable to remove the booking due to failed priority validation.`, { type: toast.TYPE.WARNING, className: 'custom-toast'})
            }, true)
        }
    }

    const toggleBulkDiscount = () => {
        setIsBulkDiscountActive(!isBulkDiscountActive)
    }

    const  makeBulkDiscount = (amount) => {
        setBulkDiscountAmount(amount !== '' ? Number(amount) : '')
    }

    const onSubmit = () => {
        const callback = async (addAppointmentResponse) => {
            const res = addAppointmentResponse && addAppointmentResponse.data;

            if(!res) {
                await confirm({
                    title: null,
                    message: addAppointmentResponse,
                    confirmText: "Ok",
                    cancelText: null,
                    confirmColor: "primary",
                    cancelColor: "link text-danger"
                });

                updateConfigBookingTicketModal({isLoading: false})
            }
            else if (res && (!res.data.unavailableAppointmentList || !res.data.unavailableAppointmentList.length)) {
                const responseStatus = Array.isArray(res.data.availableAppointmentList) && res.data.availableAppointmentList.length > 0 ? res.data.availableAppointmentList[0].status : null;

                if (responseStatus !== 'Rejected') {
                    modalStateRef.current = {...DEFAULT_MODAL_STATE}
                    setToggleUserDetailsValidate(undefined)
                    toggleBookingTicketModal({})
                }

                updateConfigBookingTicketModal({isLoading: false})
                notify(responseStatus === 'Rejected' ? 'Your booking request has been cancelled.' : 'Processing Success!', 'SUCCESS')
            } else if(res && res.data.unavailableAppointmentList) {
                // for multiple & recurring appoinments
                if (res?.data?.availableAppointmentList?.length > 0) {
                    const result = await confirm({
                        title: (
                            <ul>
                                {res.data && res.data.unavailableAppointmentList.map(ap => <div>
                                    <li className='w-100'>
                                        <div className='d-flex w-100'>
                                            <div className='d-flex flex-column pr-2 small'>
                                                <div>{_.get(_.find(sportsMemo, {serviceId: ap.serviceId}), 'serviceName', '')}</div>
                                                <div className='badge badge-info'>{_.get(_.find(facilities, {facilityId: ap.facilityId}), 'facilityName', '')}</div>
                                            </div>
                                            <div>{ap.startTime} {ap.appointmentDate}</div>
                                        </div>
                                    </li>
                                </div>)}
                            </ul>
                        ),
                        message: "Sorry, the selected slots are not available. Would you like to book only the available slots from your selection?",
                        confirmText: "Yes",
                        cancelText: "No",
                        confirmColor: "primary",
                        cancelColor: "link text-danger"
                    });

                    const status = result ? 'Active' : 'Rejected';

                    let availList = res.data.availableAppointmentList.map(ap => {
                        return {
                            ...ap,
                            additionalPricingListIds: ap.additionalPricingList?.length > 0 ? ap.additionalPricingList.map(ad => ad.id) : [],
                            halfCourtName: ap.courtName,
                            isPartial: ap.partial,
                            amount: ap.fee,
                            isWalking: "true",
                            appointmentDate: moment(ap.appointmentDate).format('YYYY-MM-DD'),
                            status
                        }
                    })

                    const submitObject = {
                        appointmentDTORqList: availList,
                        appointmentType: bookingType
                    }

                    confirmAppointment(submitObject, callback);
                } else {
                    const message = "All selected appointment slots are fully booked. Please try different time slots."

                    toast(message, {
                        className: "toast-custom",
                        type: toast.TYPE["WARNING"],
                    });

                    updateConfigBookingTicketModal({isLoading: false})
                }
            }
        }
        const userDetails = {
            email: _.get(modalStateRef.current, 'userEmail', ''),
            firstName: _.get(modalStateRef.current, 'userFirstName', ''),
            lastName: _.get(modalStateRef.current, 'userLastName', ''),
            mobileNo: _.get(modalStateRef.current, 'userPhoneNumber', '')
        }
        const submitObject = {
            appointmentDTORqList: _.map(cartBookings, item => {
                return {...item, ...userDetails}
            }),
            appointmentType: bookingType,
            isBulkBooking: isBulkDiscountActive,
            bulkBookingDiscount: bulkDiscountAmount/100
        }

        updateConfigBookingTicketModal({isLoading: true, isFetchingAppointments: true})
        submitAppointment(submitObject, callback)
    }

    const onUpdate = async () => {
        const requestObj = {
            uniqueId: uniqueId(),
            appointmentDate: currentBooking.appointmentDate,
            bookedPortionCount: currentBooking.bookedPortionCount,
            branchId: currentBooking.branchId,
            endTime: currentBooking.endTime,
            facilityId: currentBooking.facilityId,
            fullDay: currentBooking.isFullDay,
            partial: currentBooking.isPartial,
            serviceId: currentBooking.serviceId,
            startTime: currentBooking.startTime,
            facilityPortionCount: currentBooking.facilityPortionCount,
            appointmentId: editBookingObject.id
        }
        updateConfigBookingTicketModal({isLoading: true})

        await checkFacilityPriority({appointmentPriorityCheckDTOList: [requestObj]}).then(res => {
            updateConfigBookingTicketModal({isLoading: false})

            if (_.size(_.filter(res?.data?.data?.appointmentPriorityCheckDTORsList, {canMakeAppointment: true})) === 1) {
                const updatedBooking = {...currentBooking}

                if (selectedService.minNoOfGuests > 0 && guestList.length > 0) {
                    updatedBooking.guests = guestList
                }

                updatedBooking.email = _.get(modalStateRef.current, 'userEmail', '')
                updatedBooking.firstName = _.get(modalStateRef.current, 'userFirstName', '')
                updatedBooking.lastName = _.get(modalStateRef.current, 'userLastName', '')
                updatedBooking.mobileNo = _.get(modalStateRef.current, 'userPhoneNumber', '')
                updatedBooking.isWalking = true
                updatedBooking.specialNote = specialNotesRef.current.value
                updatedBooking.id = editBookingObject.id

                updateConfigBookingTicketModal({isLoading: true, isFetchingAppointments: true})
                props.updateAppointment(editBookingObject.id, updatedBooking, (success, updatedData) => {
                    if (success) {
                        notify('Appointment updated successfully', 'SUCCESS')
                        toggleBookingTicketModal({})
                    } else {
                        notify('Something went wrong! Please try again', 'WARNING')
                        toggleBookingTicketModal({})
                    }
                    updateConfigBookingTicketModal({isLoading: false, isFetchingAppointments: false})
                })
            } else {
                updateConfigBookingTicketModal({isLoading: false})
                setShowPriorityCheckMsg(true)
            }
        })
    }

    const onSaveToCartByRecurring = (bookingList) => {
        setCartBookings(bookingList)
    }

    const onCheckPriority = async (bookings = [], checkId,  onCan = () => {}, onCanNot = () => {}, isRemove = false) => {
        updateConfigBookingTicketModal({isLoading: true})

        await checkFacilityPriority({appointmentPriorityCheckDTOList: bookings}).then(res => {
            updateConfigBookingTicketModal({isLoading: false})
            let canMakeAppointment = false;
            if (isRemove) {
                canMakeAppointment = _.size(res?.data?.data?.appointmentPriorityCheckDTORsList) === _.size(_.filter(res?.data?.data?.appointmentPriorityCheckDTORsList, {canMakeAppointment: true}))
            } else {
                canMakeAppointment = _.find(res?.data?.data?.appointmentPriorityCheckDTORsList, {canMakeAppointment: true, uniqueId: checkId})
            }

            if (canMakeAppointment) {
                onCan()
            } else {
                onCanNot()
            }
        }).finally(() => {
            updateConfigBookingTicketModal({isLoading: false})
        })
    }

    return (<>
        <ModalBody>
            <Form>
                <fieldset disabled={isLoadingBookingTicket}>
                    {!isEdit && <BookingTypeButtonGroup disabled={_.size(cartBookings) > 0}
                                            bookingType={modalStateRef.current.bookingType}
                                            toggleBookingType={setBookingType}/>}
                    <UserDetails
                        userPhoneNumber={useDetails.userPhoneNumber}
                        userFirstName={useDetails.userFirstName}
                        userLastName={useDetails.userLastName}
                        userEmail={useDetails.userEmail}
                        userGender={useDetails.userGender}
                        isDisabledInputs={useDetails.isValid}
                        ref={modalStateRef}
                        toggleUserDetailsValidate={toggleUserDetailsValidate} />
                    <Row className='mt-md-3'>
                        <Col md={6}>
                            <BookingDropdown
                                id='booking-ticket-service-dropdown'
                                disabled={isEnableRecurringDateSelecting}
                                toggleValidate={toggleValidateService}
                                ref={serviceRef}
                                label='Sport'
                                displayLabel='serviceName'
                                displayLabel2='description'
                                displayLabel3='serviceFullPrice'
                                displayLabel4='(Unavailable)'
                                validateText='Please Select a Service'
                                required={true}
                                itemList={sportsMemo}
                                onSelectItem={(item) => {
                                    if (bookingDate && selectedService && selectedFacility) {
                                        updateConfigBookingTicketModal({isLoading: selectedService ? item.serviceId !== selectedService.serviceId : false})
                                    }

                                    setSelectedService(item)
                                }}/>
                        </Col>
                        {_.size(filteredFacilities) > 0 && <Col md={6}>
                            <BookingDropdown
                                id='booking-ticket-facility-dropdown'
                                disabled={isEnableRecurringDateSelecting}
                                toggleValidate={toggleValidateFacility}
                                ref={facilityRef}
                                label='Facility'
                                displayLabel='facilityName'
                                validateText='Please Select a Facility'
                                required={true}
                                itemList={filteredFacilities}
                                onSelectItem={(item) => {
                                    if (bookingDate) {
                                        updateConfigBookingTicketModal({isLoading: selectedFacility ? item.facilityId !== selectedFacility.facilityId : false})
                                    }
                                    setSelectedFacility(item)
                                }}/>
                        </Col>}
                    </Row>
                    {!isEnableRecurringDateSelecting ? <>
                        {selectedFacility &&
                            <Row>
                                <Col xs={12}>
                                    <BookingTicketDate
                                        id='booking-ticket-date'
                                        disabled={isLoadingBookingTicket}
                                        toggleValidate={toggleValidateDate}
                                        ref={dateRef}
                                        onSelectDate={(date) => {
                                            updateConfigBookingTicketModal({isLoading: bookingDate !== date})
                                            setBookingDate(date)
                                        }}
                                        selectedDate={isEdit ? moment(editBookingObject.appointmentDate).format('YYYY-MM-DD') : bookingDate}
                                        filteringId={selectedFacility.facilityId}/>
                                </Col>
                            </Row>
                        }
                        {bookingDate && <SportFacilityTimeSelector
                            availableBookings={currentBookingsRef.current.bookings}
                            bookingDate={bookingDate}
                            selectedService={selectedService}
                            selectedFacility={selectedFacility}
                            branchId={branchId}
                            cartListBookings={cartBookings}
                            onClickAddBooking={setCurrentBooking}
                        />}
                        {!_.isEmpty(currentBooking) && selectedService && selectedService.minNoOfGuests > 0 && selectedService.isRequireGuests && <Row><Col>
                            <FacilityGuest guestArray={guestList} minimumGuestCount={selectedService.minNoOfGuests} onUpdateGuestList={setGuestList} />
                            {guestList.length > 0 && <div className='small'>Current number of guests {guestList.length}</div>}
                        </Col></Row>}
                        {!_.isEmpty(currentBooking) && <Row>
                            <Col>
                                <FormGroup className='mb-0'>
                                    <Label >Special Notes</Label>
                                    <TextareaAutosize
                                        id={`booking-ticket-special-notes`}
                                        ref={specialNotesRef}
                                        placeholder="Add special notes (Optional)"
                                        className="note-area"
                                        minRows={4}
                                        maxRows={7}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>}
                    </> :
                        <SportFacilityRecurring
                            recurringDate={bookingDate}
                            recurringBooking={cartBookings[0]}
                            selectedService={selectedService}
                            onCallBack={onSaveToCartByRecurring}
                        />}
                    {!isEdit && <><Row><Col className='pb-3'><div className='pb-3 border-bottom w-100'></div></Col></Row>
                        <Row>
                            <Col>
                                <BookingTicketCart
                                    facilities={facilities}
                                    removeAppointmentFromBulk={removeBookingFromCart}
                                    appointments={cartBookings}
                                    services={sportsMemo} />
                            </Col>
                    </Row>
                    {configs.discount.isEnable && <Row>
                        <Col>
                            <div className="d-flex" style={{gap: 10}}>
                                {cartBookings.length > 0 && <CustomInput
                                    type="checkbox"
                                    id="availabilityCheck"
                                    label="Bulk discount"
                                    checked={isBulkDiscountActive}
                                    disabled={false}
                                    onChange={toggleBulkDiscount}
                                />}
                                {cartBookings.length > 0 && isBulkDiscountActive && <>
                                    <Input
                                        id="bulkDiscount"
                                        type="number"
                                        value={bulkDiscountAmount}
                                        placeholder="Bulk discount amount"
                                        invalid={bulkDiscountAmount > 100}
                                        valid={bulkDiscountAmount <= 100}
                                        disabled={!isBulkDiscountActive}
                                        style={{ width: '150px', paddingLeft: '5px', paddingRight: 0, cursor: 'pointer' }}
                                        onChange={(e) => makeBulkDiscount(e.target.value)} />

                                    <FormText> %</FormText>
                                </>}
                            </div>
                        </Col>
                    </Row>}
                    </>}
                </fieldset>
            </Form>
            {isLoadingBookingTicket && <div className="d-flex justify-content-center align-items-center position-fixed h-100 w-100 booking_ticket_loader_content">
                <Spinner color="secondary">Fetching...</Spinner>
            </div>}
        </ModalBody>
        <ModalFooter className='px-0'>
            {showPriorityCheckMsg && <Row className='w-100'>
                <Col>
                    <Alert>The selected facility is unavailable. Please choose another facility.</Alert>
                </Col>
            </Row>}
            <Row className='w-100'>
                <Col>
                    {!isEdit && bookingType === APPOINTMENT_TYPE.RECURRING && <>
                        {_.size(cartBookings) === 0 && <Button id='booking-ticket-add-recur-btn' disabled={isLoadingBookingTicket} onClick={onSaveToCart}>Recur</Button>}
                        {_.size(cartBookings) > 1 && <Button id='booking-ticket-submit-btn' className='mr-2 an-btn-info' disabled={isLoadingBookingTicket} onClick={onSubmit}>Submit</Button>}
                    </>}
                    {!isEdit && bookingType === APPOINTMENT_TYPE.MULTIPLE && <>
                        {_.size(cartBookings) > 0 && <Button id='booking-ticket-submit-btn' disabled={isLoadingBookingTicket} className='mr-2 an-btn-info' onClick={onSubmit}>Submit</Button>}
                        <Button id='booking-ticket-add-to-cart-btn' disabled={isLoadingBookingTicket || _.isEmpty(currentBooking)} onClick={onSaveToCart}>Add</Button>
                    </>}
                    {isEdit && <Button id='booking-ticket-update-btn' disabled={isLoadingBookingTicket || _.isEmpty(currentBooking)} className='an-btn-info' onClick={onUpdate}>Update</Button>}
                </Col>
                <Col xs='auto'>
                    <Button id='booking-ticket-modal-close-btn' disabled={isLoadingBookingTicket} onClick={() => {
                        modalStateRef.current = {...DEFAULT_MODAL_STATE}
                        setToggleUserDetailsValidate(undefined)
                        toggleBookingTicketModal({})
                    }}>Cancel</Button>
                </Col>
            </Row>
        </ModalFooter>
    </>)
}))

const mapStateToFacilityProps = (state) => {
    return {
        facilities: state.shop.totalFacilities,
        sports: state.shop.totalServices,
        isLoadingBookingTicket: state.baseComponents.bookingTicketReduxConfig.isLoading,
        selectedBranch: state.shop.selectedBranch,
        defaultBranch: state.shop.branches[0],
        isEdit: state.baseComponents.bookingTicketReduxConfig.isEdit,
        editBookingObject: state.baseComponents.bookingTicketReduxConfig.editBookingObject
    };
};

const SportFacility = connect(mapStateToFacilityProps, {toggleBookingTicketModal, updateConfigBookingTicketModal, submitAppointment, confirmAppointment, updateAppointment}, null, {forwardRef: true})(SportFacilityComp);

export default  SportFacility
