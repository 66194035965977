import React, {useEffect, useMemo, useRef, useState} from "react";
import {Modal, ModalHeader} from "reactstrap";
import {connect} from "react-redux";

import './bookingTicket.css'
import {toggleBookingTicketModal} from "../../actions/BaseComponentsActions";
import SportFacility from "./SportFacility/SportFacility";
import BookingTicketCustomerSearch from "./BookingTicketCustomerSearch";
import * as _ from "lodash";

const enumBusinessType = {
    SALON: 1,
    LEARNERS: 2,
    SPORTS_CLUB: 3,
    SPA_WELLNESS: 4
}

function BookingTicket(props) {
    const {isOpenModal, isEdit, isOpenCustomerSearchModal, editBookingObject, toggleBookingTicketModal, selectedBranch, defaultBranch, bookingModalUserDetails} = props
    const modalRef = useRef()
    const [useDetails, setUseDetails] = useState({})

    const branchId = useMemo(() => (!_.isEmpty(selectedBranch) ? selectedBranch : defaultBranch)['branchId'], [selectedBranch, defaultBranch])

    useEffect(() => {
        if (isOpenModal && !isOpenCustomerSearchModal && !isEdit && !_.isEmpty(bookingModalUserDetails)) {
            setUseDetails({...bookingModalUserDetails, isValid: true})
        }
    }, [isOpenModal]);

    return (<>
        <Modal innerRef={modalRef} size='lg' isOpen={isOpenModal} id="booking-ticket-modal">
            <ModalHeader >{isEdit ? "Update Appointment" : "New Appointment"}</ModalHeader>
            {enumBusinessType.SPORTS_CLUB && <SportFacility ref={modalRef} useDetails={isEdit ?
                {userPhoneNumber: editBookingObject.mobileNo,
                    userFirstName: editBookingObject.firstName,
                    userLastName: editBookingObject.lastName,
                    userGender: editBookingObject.gender,
                    userEmail: editBookingObject.email,
                    isValid: true
                }
                : useDetails} />}
        </Modal>
        <BookingTicketCustomerSearch
            branchId={branchId}
            onClose={() => {toggleBookingTicketModal({isOpenCustomerSearchModal: false})}}
            switchModal={isOpenCustomerSearchModal}
            onCallBack={(customer) => {
                setUseDetails(customer)
                toggleBookingTicketModal({isOpenCustomerSearchModal: false, isOpenModal: true})
            }}
        />
    </>);
}

const mapStateToProps = (state) => {
    return {
        bookingTicketReduxConfig: state.baseComponents.bookingTicketReduxConfig,
        businessTypeId: state.shop.businessTypeId,
        selectedBranch: state.shop.selectedBranch,
        defaultBranch: state.shop.branches[0],
        isEdit: state.baseComponents.bookingTicketReduxConfig.isEdit,
        isOpenCustomerSearchModal: state.baseComponents.bookingTicketReduxConfig.isOpenCustomerSearchModal,
        isOpenModal: state.baseComponents.bookingTicketReduxConfig.isOpenModal,
        editBookingObject: state.baseComponents.bookingTicketReduxConfig.editBookingObject,
        bookingModalUserDetails: state.baseComponents.bookingTicketReduxConfig.userDetails
    };
};

export default connect(mapStateToProps, {toggleBookingTicketModal})(BookingTicket);
