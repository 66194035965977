import React, {forwardRef, memo, useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";
import * as _ from "lodash";
import {FormFeedback, FormGroup, Label} from "reactstrap";
import DayPicker from "react-day-picker";
import moment from "moment/moment";
import {BUSINESS_TYPE} from "../../utils/common";

const WEEK_MAP = { Sunday: 0, Monday: 1, Tuesday: 2, Wednesday: 3, Thursday: 4, Friday: 5, Saturday: 6 }

const DatePicker = memo(forwardRef(function (props, ref) {
    const {selectedDate, filteringId, disabled, onSelectDate, toggleValidate, businessTypeId, shopTimeArr, holidays, timeOffs, id} = props
    const [date, setDate] = useState()

    useEffect(() => {
        if (selectedDate) {
            setDate(selectedDate)
        }
    }, [selectedDate]);

    const disabledDates = useMemo(() => {
        const dates = []
        const before = { before: new Date() }     // Disable days before today
        const shopClosedDates = { daysOfWeek: [] }

        _.forEach(shopTimeArr, (value, key) => {
            if (value.status === "Inactive") {
                shopClosedDates.daysOfWeek.push(WEEK_MAP[value.day])
            }
        })
        _.forEach(holidays, (obj) => {
            if (_.isEmpty(obj.startTime)) {
                dates.push(new Date(obj.holiday))    // shop holiday dates
            }
        })

        if (businessTypeId === BUSINESS_TYPE.SPORTS_CLUB) {
            _.forEach(timeOffs, (obj) => {
                if (filteringId === obj.facilityId && _.isEmpty(obj.startTime)) {
                    dates.push(new Date(obj.facilityOffDate))    // timeOff dates
                }
            })
        }

        dates.push(before)
        dates.push(shopClosedDates)

        return dates
    }, [filteringId])

    const onPickDate = (date, modifiers = {}) => {
        if (!modifiers.disabled) {
            const formattedDate = moment(date).format('YYYY-MM-DD');

            if (ref && ref.current) {
                ref.current.date = formattedDate
                ref.current.isValid = true
            }

            setDate(formattedDate)
            onSelectDate(formattedDate)
        }
    }

    return (
        <FormGroup >
            <Label ><span className="required">*</span>Booking Date</Label>
            <div className="text-center">
                <DayPicker
                    className={id}
                    onDayClick={onPickDate}
                    selectedDays={new Date(date)}
                    modifiers={{
                        disabled: disabledDates
                    }}
                    firstDayOfWeek={1}
                /> {disabled && <div className='booking_ticket_disabled_date_picker'/>}
            </div>
            {(ref && typeof ref.current.isValid === 'boolean' && !ref.current.isValid) && <FormFeedback valid={false} className='d-block mt-0'>Please Select a Date</FormFeedback>}
        </FormGroup>
    )
}))

const mapStateToFacilityProps = (state) => {
    return {
        businessTypeId: state.shop.businessTypeId,
        shopTimeArr: state.shop.shopTimeArr,
        leaves: state.shop.leaves,
        holidays: state.shop.holidays,
        timeOffs: state.shop.timeOffs
    };
};

const BookingTicketDate = connect(mapStateToFacilityProps, {}, null, {forwardRef: true})(DatePicker);

export default BookingTicketDate;
