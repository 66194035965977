import {
    Button,
    FormFeedback,
    FormGroup,
    FormText,
    Input,
    Label, Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Spinner
} from "reactstrap";
import * as _ from 'lodash';
import {configs} from "../../utils/configs";
import React, {forwardRef, useEffect, useState} from "react";
import {checkPhoneNumber} from "../../utils/common";
import {fetchCustomers} from "../../api/api";

const BookingTicketCustomerSearch = forwardRef(function ({switchModal, onClose, onCallBack, branchId}, ref) {
    const [open, setOpen] = useState(false)
    const [mobileNo, setMobileNo] = useState('')
    const [error, setError] = useState(false)
    const [isSearching, setIsSearching] = useState(false)

    const toggleModal = () => {
        if (_.functions(onClose) && !open === false) {
            setError(false)
            setMobileNo('')
            setIsSearching(false)
            onClose()
        }

        setOpen(!open)
    }

    const onChangeValue = (text) => {
        const isError = checkPhoneNumber(text ? text : '')

        setError(!isError)
        setMobileNo(text)
    }

    const onSearch = async () => {
        const isError = checkPhoneNumber(mobileNo)

        if (!isError) {
            setError(true)
        } else {
            setIsSearching(true)

            const customer = {
                userPhoneNumber :mobileNo,
                userFirstName: '',
                userLastName: '',
                userEmail: '',
                userGender: '',
                isValid: false
            }

            fetchCustomers(branchId, '', '', '', mobileNo, 0).then(result => {
                if (result?.data?.data?.total > 0) {
                    const resulData = result.data.data.pageList[0]

                    customer['userFirstName'] = resulData.customerFirstName
                    customer['userLastName'] = resulData.customerLastName
                    customer['userPhoneNumber'] = resulData.contactNo
                    customer['userEmail'] = resulData.email
                    customer['userGender'] = resulData.gender
                    customer['isValid'] = true

                }
            }).finally((e) => {
                if (_.isFunction(onCallBack)) {
                    onCallBack(customer)
                }
                setIsSearching(false)
            });
        }
    }

    useEffect(() => {
        if (typeof switchModal === 'boolean') {
            setOpen(switchModal)
        }
    }, [switchModal, open]);

    return (<Modal isOpen={open}>
        <ModalHeader toggle={toggleModal}>Search Customer</ModalHeader>
        <ModalBody>
            <FormGroup>
                <Label><span className="required">*</span>Phone Number</Label>
                <Input
                    disabled={isSearching}
                    type="tel"
                    name="phone"
                    id="customer-search-input-booking-phone"
                    placeholder="Enter phone number"
                    value={mobileNo}
                    onChange={(e) => onChangeValue(e.target.value)}
                    invalid={error}
                />
                <FormFeedback>Not a valid phone number</FormFeedback>
                <FormText>{configs.phoneNumberExample}</FormText>
            </FormGroup>
        </ModalBody>
        <ModalFooter>
            <Button
                id="customer-search-booking-btn"
                color="primary"
                onClick={onSearch}
                disabled={isSearching || error} >
                {isSearching ? <Spinner size="sm" /> : 'Search'}
            </Button>
        </ModalFooter>
    </Modal>)
})

export default BookingTicketCustomerSearch
